header {
  @include centered-container-row;

  width: 100%;
  height: $headerHeight;
  background-color: #1b1b1b;
  padding: 0 25px 0 21px;

  .logo-container {
    @include centered-container-row;

    cursor: pointer;
  }

  $homeSize: 25px;

  .home {
    width: $homeSize;
    height: $homeSize;
    margin-right: 10px;
  }

  .logo {
    display: flex;
    width: 130px;
  }

  @import "back-button";

  &.light {
    background: #fff;
    padding: 36px 43px;
    $homeSize: 40px;
    .home {
      width: $homeSize;
      height: $homeSize;
      margin-right: 0;
    }
    .logo {
      width: 200px;
    }
  }

  &.main-page-header {
    padding: 50px 43px;

    .logo {
      object-fit: contain;
      width: 235px;
    }
  }
  @import "user-menu";
}
