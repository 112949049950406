$p: &;
.user-menu-container {
  @include centered-container-row;
  @include justify-content-right;
  flex: 1;

  .user-menu {
    @include centered-container-row;
    @include justify-content-right;

    flex: 1;

    > div {
      position: relative;
    }

    &.light {
      .credits-info {
        color: #000;
      }
      .user-dropdown-button {
        border-color: #000;
      }
    }

    .credits-info {
      @include light-bold;

      font-size: 28px;
      color: #ffffff;
      margin-right: 18px;
      display: inline-flex;
      
      align-items: center;
      

      .credits {
        font-weight: bold;
        color: #669523;
        align-self: end;
      }

      .button {
        background-color: #3f99d3;
        margin: 0px 0px 0px 10px;
        color: #ffffff;
      }
    }

    .user-dropdown-button {
      @include centered-container-row;

      cursor: pointer;
      border-radius: 5px;
      border: solid 1px #ffffff;
      padding: 5px 10px 5px 10px;

      .user-icon {
        width: 22px;
      }
      .expand-icon {
        margin-left: 9px;
      }
    }

    @import "expanded-user-menu";
  }

}
