.login-container {
  @mixin middled {
    max-width: 445px;
    margin-left: auto;
    margin-right: auto;
  }

  background-color: #FFF;
  height: 100vh;
  display: flex;

  .photo-page {
    width: 100%;
  }

  .login-inner {
    display: flex;
  }
  .form-container {
    @include middled-container;

    flex: 1 100%;
  }
  .login-form-container,
  .update-password-form,
  .forgot-password-form,
  .login-photo {
    width: 50%;
  }

  .login-form-container {
    @include container-column;

    flex: 1 100%;

    .logo-container {
      display: flex;
      align-items: center;
      padding: 20px 0 0 43px;

      @import "./header/back-button";
      $homeSize: 40px;
      .home {
        width: $homeSize;
        height: $homeSize;
        margin-right: 0;
      }
      .logo {
        cursor: pointer;
        width: 200px;
      }
    }

    .update-password-form,
    .forgot-password-form,
    .login-form {
      min-width: calc(100% - 300px);
      align-self: center;
      width: auto;
      max-width: 420px;

      .auth-page-text {
        font-family: $fontFamilyRobotoMedium;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: -0.06px;
        color: #212529;
      }

      .auth-info {
        font-family: $fontFamilyRobotoRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.05px;
        color: $lightPageFontColor;
        margin-top: 4px;
      }

      .forgot-password-text {
        height: 20px;
        font-family: $fontFamilyRobotoRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: -0.05px;
        color: $lightPageFontColor;
        margin-top: 14px;
        float: right;
      }

      .form {
        @include middled;
        margin-top: 15px;

        .input-group {
          margin-top: 20px;

          label {
            z-index: 0;
            padding: 0;
            font-size: 16px;
            top: 14px;
            color: #000;
          }

          &:first-of-type {
            margin-top: 0;
          }

          input {
            height: 50px;
            background: repeat-y;
            font-family: $fontFamilyRobotoRegular;

            &::placeholder {
              color: transparent;
            }

            @mixin focused-input {
              z-index: 1;
              font-size: 12px;
              color: $colorLink;
              top: -7px;
              padding: 0 10px;
            }

            &:not(:placeholder-shown) + label {
              @include focused-input;
            }
          }

          &.password {
            .right-icon {
              top: 15px;
            }
          }
        }

        .button {
          margin-top: 15px;
          width: 100%;
          border-radius: 4px;
          font-family: $fontFamilyRobotoMedium;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
          background-color: $colorLink;
          height: 35px;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.14;
          letter-spacing: 1.25px;
          color: #ffffff;
          text-transform: uppercase;

          &.transparent {
            background-color: transparent;
            border: solid 1px $colorLink;
            color: $colorLink;
          }

          &.reset-password {
            background-color: #ffffff;
            color: #234361;
          }
        }
      }
    }
  }

  .login-photo {
    background: url(../../assets/images/photos/photo-page-bg.jpg) no-repeat center;
    background-size: cover;
    height: 100%;
  }

  @include media('>=1300px') {
    .login-form-container {
      .update-password-form,
      .forgot-password-form,
      .login-form {
        min-width: unset;
        width: 500px;
      }
    }
  }

  @include media('<=700px') {
    .login-form-container {
      .update-password-form,
      .forgot-password-form,
      .login-form {
        min-width: unset;
        width: 90%;
      }
    }
  }
}
