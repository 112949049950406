.main-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main-inner {
    @include middled-container;

    .main-dot-container {

      @include middled-container;
      margin: 45px;
      padding: 150px;
      //width: 1000px;
      //height: 424px;
      border-radius: 8px;
      border: dashed 1px #979797;
      background-color: #ffffff;

      .main-sub-container {
        flex-direction: column;
        align-items: stretch;
        display: flex;
        max-width: 600px;

        .text-block {
          font-family: AvenirNext;
          font-size: 16px;
          color: black;
          margin: 10px;
        }

        .button.submainpage {
          margin: 10px;
          background-color: #3f99d3;
          font-family: AvenirNext;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;  
        }
      }

      .button.mainpage {
        margin: 20px;
        width: 303px;
        height: 93px;
        border-radius: 14px;
        background-color: #3f99d3;
        font-family: AvenirNext;
        font-size: 24px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        &:active, &:focus {
          outline: 0;
          border: 0;
        }
      }
    }

    @media (max-width: 800px) {
      .main-dot-container {
        flex-direction: column;
        padding: 10px;
      }
    }
  }

}