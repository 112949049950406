.back-button {
  @include middled-container;

  border: 1px solid #fff;
  border-radius: 5px;
  padding: 5px 5px;
  cursor: pointer;
  margin-left: 10px;

  &.light {
    @include container-column;

    margin-top: 5px;
    margin-left: 5px;
    border-color: $colorPurple;

    .text {
      color: $colorPurple;
      margin-top: 5px;
      margin-left: 0;
    }
  }

  $size: 20px;
  .img {
    width: $size;
    height: $size;
  }
  .text {
    margin-left: 5px;
    font-size: 12px;
    color: #fff;
  }
}
