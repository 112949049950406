
.add-edit-lab-specialist-popup {
  background-color: #FFF !important;
  max-width: 580px;
  height: auto;
  min-height: 480px;

  &.edit-case {
    min-height: 360px;
  }

  &.export-dentists {
    min-height: 360px;
  }

  * {
    color: #000 !important;
  }

  

  .modal-header {
    border-bottom: 1px solid #e2e2e2 !important;
    padding: 10px 30px 10px 30px !important;
    justify-content: space-between;

    .add-edit-lab {
      display: flex;
      align-items: center;

      .add-icon {
        width: 22px;
        height: 22px;
        cursor: pointer;
      }

      .add-title {
        font-family: $fontFamilyRobotoMedium;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.04px;
        color: #212529;
        margin-left: 20px;

        &.view {
          margin-left: 0;
        }
      }
    }

    .closer {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .content {
    padding: 0 30px 25px 30px !important;

    .add-edit-lab-specialist-content {
      min-height: unset;



      .progress-info {
        margin: 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 300px;
      }

      .content-text {
        font-family: $fontFamilyRobotoRegular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.13px;
        color: $lightPageFontColorLighter;
        margin: 15px 0;
      }

      .input-group {
        margin-top: 10px;

        

        label {
          z-index: 0;
          padding: 0;
          font-size: 14px;
          top: 6px;
          font-family: $fontFamilyRobotoRegular;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 0.15px;
          color: $lightPageFontColorLighter;
          height: 20px;
        }

        input {
          height: 40px;
          font-family: $fontFamilyRobotoRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.15px;
          color: $lightPageFontColorLighter;
          padding: 14px 16px;

          .datepicker {
            .label {
              font-size: 12px;
        
              .required {
                display: none;
              }
            }
          }

          &::placeholder {
            color: transparent;
          }
          @mixin focused-input {
            z-index: 1;
            font-size: 12px;
            color: $colorLink;
            top: -8px;
            padding: 0 5px;
            line-height: 1.33;
            letter-spacing: 0.4px;
            height: unset;
          }

          &:not(:placeholder-shown) + label {
            @include focused-input;
          }
        }
      }
      .select-box {
        margin-top: 10px;
        height: 32px;
        position: relative;

        &.edit-case {
          height: 44px;
          .select-search {
            .input-group {
              height: 44px;
            }
            .results {
              max-height: 150px;
              .result-row {
                font-size: 16px;
                padding: 6px 12px;
      
                
              }
            }
          }
          .placeholder.has-value {
              height: 16px;
          }
        }

        @mixin labelFocus {
          z-index: 1;
          font-size: 12px;
          color: $colorLink;
          padding: 0 5px;
          letter-spacing: 0.4px;
          top: -3px;
          line-height: 0.4;
          background-color: #FFF;
          .required {
            display: none;
          }
        }

        .placeholder {
          top: 14px;

          &.has-value {
            @include labelFocus;
          }
        }
        .select-search {
          margin-top: 0;

          .input-group {
            margin-top: 0;
            height: 30px;

            input {
              height: inherit;
              cursor: default;
              &::placeholder {
                color: initial;
              }
            }
          }

          &.has-focus + .placeholder {
            @include labelFocus;
          }
        }

        &.disabled {
          .select-search {
            .input-group {
              background-image: unset;
            }
          }
        }
      }
    }
  }

  .footer-row {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;

    .confirm-button,
    .close-button {
      width: 45%;
      font-family: $fontFamilyRobotoRegular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #FFF;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;

    .access-text {
      font-family: $fontFamilyRobotoRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.13px;
      color: $lightPageFontColorLighter;
      margin: 15px 0;
    }

    .close-button,
    .save-button {
      height: 30px;
      padding: initial;
      font-family: $fontFamilyRobotoRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.13px;
      text-align: center;
      color: #FFF !important;
    }

    .backup-button {
      height: 30px;
      padding: initial;
      font-family: $fontFamilyRobotoRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.13px;
      text-align: center;
      color: #FFF !important;
      margin: 75px 0px;
    }

    .export-button {
      height: 30px;
      padding: initial;
      font-family: $fontFamilyRobotoRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: 0.13px;
      text-align: center;
      color: #FFF !important;
      margin: 215px 0px 15px 0px;
    }
  }
}
