.lookup {
  .inner {
    padding: 45px;
  }

  .cases-loader {
    display: block;
    margin: 20px auto 0 auto;
  }
  .support-info {
    margin-top: -35px;
  }

  $inputMargin: 26px;

  $patientFirstKey: 1;
  $doctorFirstKey: 2;
  $patientIdKey: 3;
  $patientIdKey2: 4;
  $patientLastKey: 5;
  $doctorLastKey: 6;
  $caseNumKey: 7;
  $doctorFirstDenKey: 8;
  $doctorLastDenKey: 9;

  .select-search {
    min-width: auto;
  }
  .fields {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-1*$inputMargin/2);
    margin-right: calc(-1*$inputMargin/2);

    > .input-group {
      flex: none;
      width: calc(33.3% - #{$inputMargin});
      margin: calc($inputMargin/2);
    }

    > .input-group-half {
      flex: none;
      width: calc(16.6% - #{$inputMargin});
      margin: calc($inputMargin/2);
    }

    @include media('<=800px') {
      > .input-group {
        width: calc(50% - #{$inputMargin});

        &:nth-child(#{$patientFirstKey}) {
          order: 1;
        }
        &:nth-child(#{$patientLastKey}) {
          order: 2;
        }
        &:nth-child(#{$doctorFirstKey}) {
          order: 3;
        }
        &:nth-child(#{$doctorLastKey}) {
          order: 4;
        }
        &:nth-child(#{$patientIdKey}) {
          order: 5;
        }
        &:nth-child(#{$patientIdKey2}) {
          order: 6;
        }
        &:nth-child(#{$caseNumKey}) {
          order: 7;
        }
        &:nth-child(#{$doctorFirstDenKey}) {
          order: 8;
        }
        &:nth-child(#{$doctorLastDenKey}) {
          order: 9;
        }
      }

      > .input-group-half {
        width: calc(25% - #{$inputMargin});

        &:nth-child(#{$patientFirstKey}) {
          order: 1;
        }
        &:nth-child(#{$patientLastKey}) {
          order: 2;
        }
        &:nth-child(#{$doctorFirstKey}) {
          order: 3;
        }
        &:nth-child(#{$doctorLastKey}) {
          order: 4;
        }
        &:nth-child(#{$patientIdKey}) {
          order: 5;
        }
        &:nth-child(#{$patientIdKey2}) {
          order: 6;
        }
        &:nth-child(#{$caseNumKey}) {
          order: 7;
        }
      }
    }
  }
  .row {
    @include container-row;

    margin-top: calc($inputMargin/2);
  }
  .input-group {
    flex: 1;
    /*&:not(:last-of-type) {
      margin-right: $inputMargin;
    }*/
  }
  form {
    * {
      color: $colorLightPageDark;
    }

    .button-container {
      $buttonPadding: 8.5px;

      flex: 1;
      text-align: left;
      padding-left: $buttonPadding;

      &:first-of-type {
        text-align: right;
        padding-left: 0;
        padding-right: $buttonPadding;
        justify-content: flex-end;
        display: flex;
      }
      button {
        @include middled-container;

        height: 40px;
        max-width: 433px;

        text-align: center;
        border: solid 1px #6db413;
        background: #6db413;
        width: 100%;

        span {
          color: #ffffff;
          font-family: $fontFamilySecondary;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.14;
          letter-spacing: 1.25px;
          text-transform: uppercase;
        }

        img {
          margin-right: 12px;
          width: 15px;
        }
        &.transparent {
          background: #fff;

          span {
            color: #6db413;
          }
        }
      }
    }
  }
  .element-loader {
    position: relative;
    background: transparent;
    margin-top: 20px;
    margin-bottom: 20px;

    * {
      color: #212529 !important;
    }
  }
  .resize-element {
    display: none;
  }

  .cases {
    margin-top: 25px;

    .photo-link {
      &:not(:last-of-type) {
        margin-right: 5px;
      }
    }
    .remove {
      background: url('../../assets/images/icons/remove.png');
      width: 27px;
      height: 27px;
      background-size: contain;
      cursor: pointer;
      background-repeat: no-repeat;
    }
    .edit {
      background: url('../../assets/images/icons/edit-case.png');
      width: 27px;
      height: 27px;
      background-size: contain;
      cursor: pointer;
      background-repeat: no-repeat;
    }
    .row {
      margin-top: 0;
    }

    .cell {
      min-height: 50px;
      width: 31px;
      height: 14px;
      font-family: $fontFamilySecondary;
      font-size: 12px;
      text-align: left;
      padding: 0 12px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .header {
      .cell {
        @include centered-container-row;
        background: $colorLink;
        white-space: initial;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
      }
    }
    .body {
      .cell {
        color: #000000;

        a {
          color: $colorLink;
          text-decoration: underline;
        }
      }
      .row {
        border-bottom: none;
        background: #fff;

        &:nth-child(even) {
          background: #fae7ff;
        }
      }

      // lab and specialist
      .input-cell {
        padding: 0;
        overflow: initial;
      }

      .alt-id {
        .input-cell {
          padding: 0px;
        }
      }
    }

    $dateClass: 'date';
    $doctorFirstClass: 'doctor-first';
    $doctorLastClass: 'doctor-last';
    $patientFirstClass: 'patient-first';
    $patientLastClass: 'patient-last';
    $labClass: 'lab';
    $specialistClass: 'specialist';
    $caseClass: 'case';
    $imageClass: 'image';
    $statusClass: 'status';
    $actionClass: 'action';
    $ascendClass: 'ascend-case';
    $altIdClass: 'alt-id';
    

    .cell {
      &.#{$dateClass} {
        min-width: 100px;
      }
      &.#{$doctorFirstClass} {
        flex: 1 9%;
      }
      &.#{$doctorLastClass} {
        flex: 1 9%;
      }
      &.#{$patientFirstClass} {
        flex: 1 9%;
      }
      &.#{$patientLastClass} {
        flex: 1 9%;
      }
      &.#{$labClass} {
        flex: 1 10%;
      }
      &.#{$specialistClass} {
        flex: 1 10%;
      }
      &.#{$caseClass} {
        flex: 1 9%;
      }
      &.#{$altIdClass} {
        flex: 1 9%;
      }
      &.#{$ascendClass} {
        flex: 1 5%;
      }
      &.#{$imageClass} {
        flex: 1 9%;
      }
      &.#{$statusClass} {
        flex: 1 5%;
      }
      &.#{$actionClass} {
        flex: 1 7%;
        display: flex;
        flex-direction: row;
        gap: 6px;
      }
    }
    .body {
      .cell {
        .status {
          padding: 5px 10px;
          text-transform: capitalize;
          border-radius: 5px;
          display: inline-block;
          color: #fff;

          &.new {
            background: #2c77d7;
          }
          &.complete {
            background: #1a9727;
          }
          &.done {
            background: #1a9727;
          }
        }
        
        
      }
    }


    .dentrix-image {
        max-height: 40px;
        width: 80%;
    }

    .alt-id {
      
      input {
        width: 100%;
        height: 50px;
        border-radius: 4px;
        border: solid 1px rgba(0, 0, 0, 0.32);
        font-family: $fontFamilySecondary;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: 0.15px;
        padding: 16px;
  
        &:disabled, &:read-only {
          background: #f2f2f2;
        }
        &:focus {
          padding: 0 15px;
          border: solid 2px $colorLink;
        }
      }
    }

    @include media('<=1200px') {
      .header {
        .cell {
          &.#{$dateClass} {
            color: transparent;
            position: relative;

            &:before {
              content: 'Date / Case';
              color: #fff;
              position: absolute;
              left: 12px;
            }
          }
          //&.#{$statusClass} {
          //  display: none;
          //}
          //&.#{$ascendClass} {
          //  display: none;
          //}
        }
      }
      .cell {
        height: auto;
        align-content: center;

        //&.#{$statusClass} {
        //  display: none;
        //}
        //&.#{$ascendClass} {
        //    display: none;
        //}
        //.case {
        //  display: block;
        //}
      }
      .body {
        .cell {
          min-height: auto;
        }
      }
    }
    @include media('<=1400px') {
      .header {
        .cell {
          &.#{$doctorFirstClass}, &.#{$patientFirstClass} {
            color: transparent;
            position: relative;

            &:before {
              content: 'Doctor Name';
              color: #fff;
              position: absolute;
              left: 12px;
            }
          }
          &.#{$patientFirstClass} {
            &:before {
              content: 'Patient Name';
            }
          }
        }
      }
      .cell {
        &.#{$doctorLastClass}, &.#{$patientLastClass} {
          display: none;
        }
        &.#{$doctorFirstClass}, &.#{$patientFirstClass} {
          @include justified-container-column;
          padding-top: 0;
          padding-bottom: 0;
        }

        .doctor-last, .patient-last {
          display: block;
        }
      }
    }
    @include media('<=1100px') {

      .cell {
        &.#{$dateClass},&.#{$doctorFirstClass},&.#{$patientFirstClass},&.#{$caseClass} {
          flex: 1 1 25%;
        }
        &.#{$ascendClass} {
            flex: 1 1 10%;
          }
        &.#{$specialistClass}, &.#{$labClass}, &.#{$imageClass}, &.#{$actionClass}, &.#{$altIdClass}  {
          display: none;
        }
        &.#{$statusClass} {
          flex: 1 1 80px;
          justify-content: flex-end;
          padding-right: 15px;

          .status {
            float: right;
            margin-right: 10px;
          }
        }
      }
      .input-cell {
        @include centered-container-row;
      }
      .select-search__value {
        padding: 0;
        background-position: calc(100% - 10px) center;
      }
      .status {
        padding: 2px;
      }
      .body {
        .status {
          padding: 2px;
        }
      }
      .remove {
        width: 22px;
        height: 22px;
      }

    }

    @include media('<=700px') {

        .cell {
          &.#{$dateClass},&.#{$doctorFirstClass},&.#{$patientFirstClass} {
            flex: 1 1 28%;
          }
          &.#{$specialistClass}, &.#{$labClass}, &.#{$imageClass}, &.#{$actionClass}, &.#{$ascendClass}, &.#{$statusClass}, &.#{$altIdClass}  {
            display: none;
          }
          &.#{$caseClass} {
            flex: 1 1 140px;
            //justify-content: flex-end;
            padding-right: 15px;
  
            .case {
              float: right;
              margin-right: 10px;
            }
          }
        }
        .input-cell {
          @include centered-container-row;
        }
        .select-search__value {
          padding: 0;
          background-position: calc(100% - 10px) center;
        }
        .status {
          padding: 2px;
        }
        .body {
          .status {
            padding: 2px;
          }
        }
        .remove {
          width: 22px;
          height: 22px;
        }
  
      }

    @include media('<=500px') {
      .fields {
        @include container-column;
        margin: 0;

        > .input-group {
          width: 100%;
          margin: calc($inputMargin/2) 0;
        }
      }
      .row {
        &.row-buttons {
          @include container-column;

          .button-container {
            padding: 0;

            &:not(:first-of-type) {
              margin-top: $inputMargin;
            }
          }
        }
      }
    }
  }

  .select-search__value {
    .select-search__input {
      width: 100%;
    }
  }
  @include media('<=800px') {
    .inner {
      padding: 45px 10px;
    }
  }
}
