$fontFamilyMain: 'AvenirNext';
$fontFamilyAvenirNextDemiBold: 'AvenirNextDemiBold';
$fontFamilySecondary: 'Roboto';
$fontFamilyIcon: 'CustomIcon';
$fontFamilyRobotoRegular: 'RobotoRegular';
$fontFamilyRobotoMedium: 'RobotoMedium';

$headerHeight: 42px;
$topMenuHeight: 55px;
$workAreaHeight: calc(100vh - #{$topMenuHeight} - #{$headerHeight});

$leftMenuWidth: 131px;

$transition: .3s;
$leftMenuTransition: $transition;

$lightPageFontColor: #000; // was #9ea0a5
$lightPageFontColorLighter: #000; // was rgba(0, 0, 0, 0.6)
$pageBG: #282828;
$colorDark: #272727;
$colorLightPageDark: $lightPageFontColorLighter;
$colorDisabled: #666666;
$colorLink: #883b9f;
$colorPurple: #694873;

$commontText: 12px;
$mediumText: 14px;
