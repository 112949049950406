.ascend-images-container {
	display: flex;
	flex: 1 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.ascend-half-container {
		display: flex;
		flex: 0 auto;
        flex-direction: column;
        align-items: center;

		&.cont-row {
			flex-direction: row;
			column-gap: 20px;
		}

		.ascend-images-root {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;
			overflow: hidden;
            padding: 10px;
            border: 2px inset lightgray;
            margin: 10px;
		}

		.ascend-images-gridList {
			width: 1200px;
			color: white;
            max-height: 30vh;
            flex-wrap: nowrap;
		}

		.ascend-images-img-container {
			display: flex;
			flex: 1 100%;
			justify-content: center;
			flex-direction: column;
			width: 100%;
			height: 100%;
			align-items: center;
            padding: 2px;
            border: 1px solid lightgray;
		}

		.ascend-image-tilebar * {
			color: white;
		}

		.ascend-images-img {
			width: auto;
			height: 100%;
			cursor: pointer;
		}

		.ascend-image-tilebar {
			cursor: pointer;
		}

        .button {
            margin-top: 15px;
            width: 100%;
            border-radius: 4px;
            font-family: $fontFamilyRobotoMedium;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
                0 1px 1px 0 rgba(0, 0, 0, 0.14);
            background-color: $colorLink;
            min-height: 52px;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.14;
            letter-spacing: 1.25px;
            color: #ffffff;
            text-transform: uppercase;

            &.transparent {
                background-color: transparent;
                border: solid 1px $colorLink;
                color: $colorLink;
            }

            &.reset-password {
                background-color: #ffffff;
                color: #234361;
            }
        }
	}
}
