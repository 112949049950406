.edit {
  padding: 0 $padding;

  .rotate {
    img {
      padding: 3px;
      border: 1px solid rgba(151, 151, 151, 0.5);
      border-radius: 5px;
      width: 28px;

      &.disabled {
        opacity: 0.5;
      }

      &.flip {
        width: 28px;
      }
    }
  }

  .incremental-rotate {
    .degrees-icon {
      width: 28px !important;
      height: 28px;
    }
    .degrees-icon-up {
        width: 28px !important;
        height: 28px;
    }
  }
}
