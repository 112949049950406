.case {
  @import "top-menu";
  @import "work-area";

  position: relative;
  color: white;
  .button {
      color: white;
  }

  .shade-master-colors-area {
      
    margin: 0px 14px 0px 14px;
    padding: 8px 10px 10px 10px;
    background-color: #444444;
    .row-buttons {
        > input {
            width: 54px;
            height: 21px;
            margin: 0 0 0px 0px;
            border-radius: 2px;
            background-color: #ffffff;
            color: #000000;
            padding-left: 4px;
        }
    }
  }
}
