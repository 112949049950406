.left-menu {
  @include centered-container-column;

  align-self: flex-start;
  z-index: 1;
  min-height: $workAreaHeight;
  background: #424242;
  width: $leftMenuWidth;
  min-width: $leftMenuWidth;
  transition: $leftMenuTransition;

  &.hide {
    margin-left: -$leftMenuWidth;
  }
  > div {
    @include centered-container-column;
    width: 100%;
  }

  .top {
    flex: 1;
  }

  $border: 1px solid #1b1b1b;

  .button {
    border-radius: 0;
    width: 100%;
    padding: 13px 0 13px 20px;
    border-top: $border;

    > div {
      @include centered-container-row;
    }
    &.last {
      border-bottom: $border;
    }
    .text {
      @include light-bold;

      margin-left: 10px;
      font-size: $commontText;
    }

    &.active {
      background: $colorDark;
    }

    &.disabled {
      cursor: default;

      img {
        opacity: .4;
      }
      .text {
        color: $colorDisabled;
      }
    }
  }
}
