@import "button";
@import "checkbox";
@import "switch-buttons";
@import "radio-group";
@import "select";
@import "input";
@import "textarea";
@import "row";

.field-error {
  margin-top: 10px;
  color: #ff0000 !important;
}
.form-error {
  margin-bottom: 10px;
  color: #ff0000 !important;

  padding: 10px 20px;
}
