.cropper {
  position: absolute;
  z-index: 1;

  .cropper-container {
    img {
      //display: none;
    }
  }
}
