.top-menu {
  @include container-row;

  height: $topMenuHeight;
  padding: 0 20px;
  background: #545353;

  .menu-opener {
    @include centered-container-row;

    cursor: pointer;

    .text {
      @include light-bold;

      margin-left: 9px;
      font-size: 14px;
    }
  }

  .case-notes {
    @include centered-container-row;

    margin-left: 15px;
    flex: 1;

    .button {
      @include centered-container-row;
      @include justified-container-row;
    }
    .case-checkbox {
      margin-left: 14px;
      min-width: 100px;

      &.disabled {
        .icon {
          background-color: #c1c1c1;
        }
        .text {
          color: #c1c1c1;
        }
      }
    }

    .case-notes-icon {
        margin-right: 4px;
        margin-left: 4px;

    }
  }

  .photo-control {
    @include centered-container-row;

    .used-shade-tabs {
        display: flex;

        .shade-tab {
            font-size: 14px;
            margin-left: 8px;
            margin-right: 8px;
        }
    }

    .button-horizontal-samesize {
        display: flex;
        
        >button {
            
            flex: 1 1 auto;
            min-width: 100px;
        }
    }

    .breaker {
      margin: 0 20px;
      height: $topMenuHeight;
      width: 1px;
      border: solid 1px $pageBG;
    }

    .photo-button {
      margin-right: 9px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: default;
      font-size: 10px;

      &:last-of-type {
        margin-right: 100px;
      }
    }

    .photo-button-white {
      background-color: #ffffff;
      color: #000000;
      
    }

    .photo-button-black {
      background-color: #000000;
      color: #ffffff;
    }

    .photo-button-gray {
      background-color: #646464;
      color: #ffffff;
    }

    .photo-button-shade {
      background-color: #e5dab9;
      color: #000000;
      
    }

    .photo-button-selected {
      border: 2px solid green;
    }

    .photo-button-current {
      border: 2px green;
      animation: btnmpanim 1s infinite;
    }

    @keyframes btnmpanim {
      0% {
          border-style: solid;
      }
      25% {
          border-style: dotted;
      }
      50% {
          border-style: dashed;
      }
      75% {
          border-style: groove;
      }
      100% {
        border-style: solid;
      }
    }
  }
}
