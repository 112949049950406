.create-dentist-profile {
  @mixin middled {
    max-width: 445px;
    margin-left: auto;
    margin-right: auto;
  }

  .form-steps {
    @include middled;
  }
  .title {
    @include middled;
    font-family: $fontFamilyRobotoMedium;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.06px;
    color: #212529;
    margin-top: 40px;

    .sub-title {
      font-family: $fontFamilyRobotoRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: -0.05px;
      color: $lightPageFontColor;
      margin-top: 5px;
    }
  }
  .form {
    @include middled;

    display: block !important;
    margin-top: 15px;

    .input-group {
      margin-top: 10px;

      label {
        background: transparent;
        z-index: 0;
        padding: 0;
        font-size: 16px;
        top: 8px;
        font-family: $fontFamilyRobotoRegular;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: $lightPageFontColorLighter;
      }
      &:first-of-type {
        margin-top: 0;
      }

      input {
        height: 37px;
        font-family: $fontFamilyRobotoRegular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: $lightPageFontColorLighter;

        &::placeholder {
          color: transparent;
        }
        @mixin focused-input {
          z-index: 1;
          font-size: 12px;
          color: $colorLink !important;
          padding: 0 5px !important;
          letter-spacing: 0.4px;
          top: 0 !important;
          line-height: 0.2;
          background: #fff;
        }

        

        &:focus + label, &:not(:placeholder-shown) + label {
          @include focused-input;

          .input-placeholder {
            color: $colorLink;

            &.disabled {
                background-color: rgb(242, 242, 242);
            }
          }
        }
      }

      &.has-error {
        input {
          box-shadow: rgb(255, 0, 0) 0 0 1.5px 1px;

          &:not(:placeholder-shown) + label {
            line-height: 0.4;
            top: -3px;

            .required {
              display: none;
            }
          }
        }
      }
    }
    .select-box {
      margin-top: 10px;
      height: 37px;
      position: relative;

      @mixin labelFocus {
        z-index: 1;
        font-size: 12px;
        color: $colorLink;
        padding: 0 5px;
        letter-spacing: 0.4px;
        top: -2.5px;
        line-height: 0.3;
        background-color: #FFF;
        .required {
          display: none;
        }
      }

      .select-search {
        height: 37px;

        .input-group {
          input::placeholder {
            color: initial;
          }
        }

        &.has-focus + .placeholder {
          @include labelFocus;
        }
      }
      .placeholder {
        &.has-value {
          @include labelFocus;
        }
      }
    }
    .button {
      margin-top: 40px;
      width: 100%;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
      background-color: $colorLink;
      height: 35px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      letter-spacing: 1.25px;
      color: #ffffff;
      text-transform: uppercase;
      font-family: $fontFamilyRobotoMedium;
      font-stretch: normal;
      font-style: normal;
      text-align: center;

      &.transparent {
        background-color: transparent;
        border: solid 1px $colorLink;
        color: $colorLink;
      }
    }
  }

  .search-control {
    margin-top: 10px;

    .button {
      flex: 1 100%;
      margin-top: 0;
    }
    .breaker {
      margin: 0 14px;
      font-family: $fontFamilyRobotoRegular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.15px;
      color: $lightPageFontColorLighter;
    }
  }
  .passwords-group {
    margin-top: 10px;
  }
  .labs-collapse,
  .specialist-collapse {
    @include middled;

    margin-top: 10px;

    .table-container {
      .scroll-container {
        width: 100% !important;
      }
      .header,
      .body {
        .row {
          .cell {
            flex: unset;
            display: inline-block;
            padding: 5px 0 5px 15px;
            width: 32.5%;
          }
          .first-cell {
            width: 22.5%;
          }
          .last-cell {
            width: 45%;
          }
        }
      }
      .body {
        .row {
          height: auto;
          .last-cell {
            overflow-wrap: break-word;
            word-wrap: break-word;
            white-space: unset;
            hyphens: auto;
          }
          .no-result {
            width: 100%;
          }
        }
      }
    }
  }
  .submit-control {
    margin-top: 70px;

    > * {
      &:first-of-type {
        margin-right: 6.5px;
      }
      &:last-of-type {
        margin-left: 6.5px;
      }
    }
  }
  &.lab-add {
    .submit-control {
      margin-top: 70px;

      > * {
        &:first-of-type {
          margin-right: 6.5px;
        }
        &:last-of-type {
          margin-left: 6.5px;
        }
      }

      &.add-form-open {
        margin-top: 15px;

        .button {
          margin-top: 0;
        }
      }
      &.no-result {
        margin-top: 60px;

        .button {
          margin-top: 0;
        }
      }
      &.search-result {
        margin-top: 30px;

        .button {
          margin-top: 0;
        }
      }
    }
  }

  &.specialist-add {
    .submit-control {
      margin-top: 25px;
      .button {
        margin-top: 0;
      }
    }
  }
  .search-results {
    margin-top: 18px;
    margin-bottom: -90px;

    .header {
      .cell {
        color: #fff;
      }
    }
    .cell {
      width: 33%;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .body {
      background: transparent;

      .row {
        border-bottom: none;
        cursor: pointer;

        &:nth-child(even) {
          background: #e2f2ff;
        }
      }
      .cell {

      }
    }
  }

  .checkbox-control {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 25px;

    > div {
      width: 100%;

      &:not(:first-of-type) {
        margin-top: 9px;
      }
    }
    .text {
      height: auto;
      a {
        color: $colorLink;
      }
      .required {
        color: #ff0909;
      }
    }
  }

  &.lab-add,
  &.specialist-add {
    .title {
      width: 100%;
    }
    .content {
      display: flex;
      flex-direction: column;

      .form {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        &.bottom-form {
          flex: 1;
        }
      }

      .add-form {
        @include middled;
        width: 100%;
        &.form {
          @include middled;
        }
      }
    }
  }
  &.account-created {
    .content {
      width: 50%;
      max-width: 50%;
    }
  }
}
