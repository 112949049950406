$padding: 16px 22px;
$border: solid 1px rgba(0, 0, 0, 0.32);
@mixin focused-input {
  z-index: 1;
  font-size: 12px;
  color: $colorLink;
  top: -8px;
  padding: 0 5px;
  line-height: 1.33;
  letter-spacing: 0.4px;
  background-color: #FFF;
  pointer-events: initial;

  .required {
    display: none;
  }
}

.light-page {
  .select-box {
    display: block;

    border: $border;
    border-radius: 4px;
    background-color: #ffffff;

    &.disabled {
      background: #ededed;
    }

    .placeholder {
      position: absolute;
      z-index: 0;
      font-size: 16px;
      top: 8px;
      font-family: $fontFamilyRobotoRegular;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: 0.15px;
      color: $lightPageFontColorLighter;
      transition: $transition;
      left: 12px;
      padding: 0;
      pointer-events: none;

      .required {
        color: red;
        margin-left: 2px;
      }
      &.has-value {
        @include focused-input;
      }
    }

    &.required {
      /*border: none;*/
      border-radius: 4px;

      .select-search {
        .select-search__value {
          .select-search__input {
            border: solid 1px rgba(0, 0, 0, 0.32);
            border-radius: 4px;
            width: 100%;
          }
        }
      }
    }
  }
  .select-search {
    min-width: 200px;

    .input-group {
      padding: 0;
      background-image: url('../../../../assets/images/arrow-down-dark.png');
      background-position: calc(100% - 15px) center;
      width: 100%;
    }
    .select-search__value {
      padding: 16px 0 16px 22px;
      background-image: url('../../../../assets/images/arrow-down-dark.png');
      background-position: calc(100% - 8px) center;
    }

    &.autocomplete-input-container {
      .results {
        padding-top: 0;
        position: absolute;
        max-height: 300px;
        background: #fff;
        border: solid 1px rgba(0, 0, 0, 0.32);
        overflow-y: scroll;

        &.to-top {

        }
        &.to-bottom {

        }

        .result-row {
          color: $colorLightPageDark;
          padding: 10px 15px;

          &.active, &:hover {
            background: #bbfff6;
          }
        }
      }
    }

    .input-group {
      input {
        font-size: 16px;
        letter-spacing: 0.15px;
        color: $colorLightPageDark;
        border: none;
        width: 80%;

        &:focus {
          border: none;
        }
        &::placeholder {
          color: $colorLightPageDark;
        }
      }
    }

    .select-search__select {
      position: absolute;
      left: 0;
      padding: $padding;
      border: $border;
      border-radius: 4px;
      border-top: none;
      max-height: 300px;
      overflow-y: auto;
      background: #fff;
      margin-top: -1px;
      width: calc(100% + 2px);
      margin-left: -1px;
      z-index: 2;

      button {
        font-size: 16px;
        letter-spacing: 0.15px;
        padding-left: 2px;
        color: $colorLightPageDark;
      }

      .select-search__row{
        &:first-of-type {
          button {
            padding: 0;
          }
        }
      }
    }
    &.has-focus + .placeholder {
      @include focused-input;
    }
  }
}
.select-search {
  .select-search__value, .input-group {
    background-image: url('../../../../assets/images/icons/arrow-down.svg');
    background-size: 14px;
    background-position: right center;
    background-repeat: no-repeat;
    width: 95%;
  }
  .input-group {
    input {
      background: transparent;
      color: #fff;
      border: none;
      padding-left: 5px;
      width: 90%;
      overflow: hidden;

      &~label {
        display: none;
      }

      &::placeholder {
        color: #fff;
        opacity: 1;
        text-overflow: ellipsis;
      }
    }
  }

  .select-search__input {
    outline: none !important;
  }

  &.autocomplete-input-container {
    .results {
      background: #444;
      position: relative;
      max-height: none;
      border: none;
      padding-top: 10px;
      padding-right: 5px;

      .result-row {
        padding: 10px 5px;
        cursor: pointer;

        &.active, &:hover {
          background: #111;
        }
      }
    }
  }

  .select-search__select, .results {
    li {
      list-style: none;
    }
    button {
      background: transparent;
      color: #fff;
      border: none;
      padding: 15px 0 0 0;
      text-align: left;
      cursor: pointer;
      width: 100%;
    }
  }
}

.menu-list-container {
  max-height: 400px !important;

  li {
    color: #000 !important;
  }
}

.select-box.required {
  .select-search {
    .select-search__value {
      width: 100%;
    }
  }
}
