.account-created {
  .content {
    @include container-column;

    header {
      .logo {
        width: 200px;
      }
    }

    .form-steps {
      margin-top: 25px;
    }
  }
  .message {
    @include middled-container;
    @include container-column;

    flex: 1 100%;

    .telegram {
      width: 130px;
    }

    .title {
      color: #000000;
      margin-top: 33px;
      font-family: $fontFamilyRobotoMedium;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }

    .login-info {
      color: #000000;
      margin-top: 12px;
      font-family: $fontFamilyRobotoRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;


      a {
        color: $colorLink;
      }
    }
  }

  &.create-lab-profile {
    .content {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;

      .form-steps {
        justify-content: center;
      }
    }
  }
}
