$containerPadding: 17px;
$height: calc(100vh - #{$topMenuHeight} - #{$headerHeight});

.photo-container {
	

	@include container-column;

	overflow: hidden;
	padding: $containerPadding 19px;
	width: 100%;
	flex: 1 100%;
	height: $height;
	max-height: $height;
	transition: $transition;
	position: relative;

	.inner {
		flex: 1 100%;
	}

	.print-area {
		//padding-bottom: 20px;
		width: 100%;
		height: 100%;
		color: black;
		background-color: blue;
		flex: 1 100%;
		flex-direction: column;
		display: flex;
		align-content: center;
		align-items: stretch;

		.container-text-annotation {
			height: 160px;
			padding: 20px;
			flex: 0 0%;
			flex-direction: row;
			display: flex;
			align-self: stretch;

			.block-text-annotation {
				flex: 1 50%;
				flex-direction: column;
				display: flex;

				.text-label {
					white-space: pre;
					color: black;
					background: white;
				}
			}
		}

		.img-print-content {
			display: flex;
			flex: 1 1 auto;
			flex-direction: row;
			align-self: center;
			align-content: center;
			align-items: center;
			padding: 20px;
		}

		.img-print {
			display: flex;
			flex: 1 1 auto;
			align-self: center;
			max-height: calc(100vh - 200px);
			width: auto;
		}
	}

	.cropper-control {
		@include middled-container;

		position: absolute;
		z-index: 2;
		padding: 4px;
		background: rgba(84, 83, 83, 0.4);
		top: 0;
		width: 84px;
		left: calc(50% - 32px);
		border-radius: 5px;

		.button {
		}
	}

	.photo {
		width: 100%;
	}

	.zoom-info {
		background: #545353;
		opacity: 0.7;
		padding: 2px 2px 2px 10px;
		position: absolute;
		z-index: 1;
		top: 1px;
		left: 1px;
		font-size: $commontText;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;

		.button {
			margin-left: 5px;
		}
		&:hover {
			opacity: 1;
		}
	}
}


