.edit-lab-profile-container {
  display: flex;
  flex-direction: column;

  .edit-lab-title {
    padding-top: 3.5%;

    label {
      padding-left: 9.5%;
      font-family: $fontFamilyRobotoMedium;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: -0.06px;
      color: #212529;
    }
  }

  .edit-lab-profile {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: space-between;

    .fields {
      display: flex;
      flex-direction: row;
      width: 100%;

      .passwords-group {
        margin-top: 10px;

        .password {
          .right-icon {
            top: 15px;
          }
        }
      }

      .input-group {
        margin-top: 10px;

        label {
          z-index: 0;
          padding: 0;
          font-size: 16px;
          top: 14px;
          font-family: $fontFamilyRobotoRegular;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.15px;
          color: $lightPageFontColorLighter;
        }
        &:first-of-type {
          margin-top: 0;
        }

        input {
          height: 50px;
          font-family: $fontFamilyRobotoRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.15px;
          color: $lightPageFontColorLighter;
          &::placeholder {
            color: transparent;
          }
          @mixin focused-input {
            z-index: 1;
            font-size: 12px;
            color: $colorLink;
            top: -8px;
            padding: 0 5px;
            line-height: 1.33;
            letter-spacing: 0.4px;
          }

          &:not(:placeholder-shown) + label {
            @include focused-input;
          }
        }
      }
      .select-box {
        margin-top: 10px;
        height: 50px;
        position: relative;

        @mixin labelFocus {
          z-index: 1;
          font-size: 12px;
          color: $colorLink;
          padding: 0 5px;
          letter-spacing: 0.4px;
          top: -3px;
          line-height: 0.4;
          background-color: #FFF;
          .required {
            display: none;
          }
        }

        .placeholder {
          top: 14px;

          &.has-value {
            @include labelFocus;
          }
        }
        .select-search {
          height: 50px;

          .input-group {
            input::placeholder {
              color: initial;
            }
          }

          &.has-focus + .placeholder {
            @include labelFocus;
          }
        }
      }
      .profile-info {
        width: 47.5%;
        padding: 0 2.5% 0 9.5%;

        .create-lab-profile-select {
          width: 100%;

          svg {
            fill: #000;
          }
        }
      }

      .profile-info,
      .additional-info {
        margin-top: 2%;

        .additional-email-case-assign {
          font-family: $fontFamilyRobotoRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.15px;
          color: $lightPageFontColorLighter;
          margin-top: 10px;
          display: block;
        }

        .slider {
          margin-top: 10px;
        }

        .checkbox {
          margin-top: 15px;

          .text {
            font-family: $fontFamilyRobotoRegular;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: -0.05px;
            color: $lightPageFontColor;
            height: auto;
          }

          .icon {
            border-radius: 0;
            border: 2px solid #425a70;
          }
        }
      }

      .additional-info {
        width: 52.5%;
        padding: 0 17% 0 0;

        .more-information-container {

          .more-information {
            display: flex;
            flex-direction: column;

            .horizontal-line {
              margin: 15px 0;
            }

            .customer-number-container,
            .renewal-date-container,
            .connected-dentists-container {
              display: flex;
              justify-content: space-between;

              .title {
                font-family: $fontFamilyRobotoRegular;
                font-size: 18px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.17px;
                color: #0066AA;
              }

              .value {
                font-family: $fontFamilyRobotoMedium;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.17px;
                color: rgba(0, 0, 0, 0.8);
              }
            }

            .renewal-date-container {
              margin-top: 15px;
            }

            .connected-dentists-container {
              display: flex;
              flex-direction: column;
              height: auto;
              max-height: 355px;
              overflow-y: auto;

              .dentist {
                display: flex;
                justify-content: space-between;
                margin-top: 15px;

                .name {
                  font-family: $fontFamilyRobotoRegular;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 0.15px;
                  color: $lightPageFontColorLighter;
                }

                .dentist-list-label-date {
                    margin-right: 15px;
                    &.expired {
                        color: red;
                    }
                }

                .dentists-print-icon {
                    padding: 0px 6px;
                }

                .delete-dentist-button {
                  font-family: $fontFamilyRobotoRegular;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 0.15px;
                  color: #0066AA;
                  cursor: pointer;
                  background-color: transparent;
                  border: none;
                  outline: none;
                }
              }
            }
          }
        }
      }
    }

    .more-doctors-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        .hidden {
            display: none;
        }
    }

    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;

      .buttons-container {
        width: 100%;
        display: flex;
        justify-content: space-around;

        .save-button,
        .cancel-button {
          width: 45%;
          max-width: 500px;
          border-radius: 4px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
          font-family: $fontFamilyRobotoMedium;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: 1.25px;
          text-align: center;
          color: #ffffff;
        }

        .save-button {
          background-color: #6db413;
        }
      }

      .support-info {
        margin-top: 18px;
        font-family: $fontFamilyRobotoRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000000;

        a {
          color: $colorLink;
        }
      }
    }
  }
}

@media print {
    * {
        color: black;
    }
    body {
        -webkit-print-color-adjust: exact;
    }

    @page portraitOr {
        size: portrait;
        orientation: portrait;
        margin: 1cm;
    }
    
    .edit-lab-profile-container {
        page: portraitOr;
    }

    .connected-dentists-container {
        
        display: flex;
        flex-direction: column;
        height: auto;
        //max-height: 355px;
        overflow-y: auto;

        .title {
            font-family: $fontFamilyRobotoRegular;
            font-size: 18px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.17px;
            color: #0066AA;
          }

          .value {
            font-family: $fontFamilyRobotoMedium;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.17px;
            color: rgba(0, 0, 0, 0.8);
          }

        .dentist {
          display: flex;
          justify-content: space-between;
          margin-top: 15px;

          .name {
            font-family: $fontFamilyRobotoRegular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.15px;
            color: $lightPageFontColorLighter;
          }

          .dentist-list-label-date {
              margin-right: 15px;
              &.expired {
                  color: red;
              }
          }

          .dentists-print-icon {
              padding: 0px 6px;
          }

          .delete-dentist-button {
            font-family: $fontFamilyRobotoRegular;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.15px;
            color: #0066AA;
            cursor: pointer;
            background-color: transparent;
            border: none;
            outline: none;
          }
        }
      }


}

@include media('<=700px') {
  .edit-lab-profile-container {
    .edit-lab-title {
      label {
        padding-left: 5%;
      }
    }
    .edit-lab-profile {
      flex-direction: column;

      .fields {
        display: flex;
        flex-direction: column;

        .input-group {
          &.website {
            margin-top: 10px;
          }
        }
        .profile-info,
        .additional-info {
          width: 100%;
          padding: 0 5%;
        }

        .additional-info {
          margin-top: 0;
        }
      }
      .footer {
        .buttons-container {
          flex-direction: column;
          align-items: center;

          .save-button,
          .cancel-button {
            max-width: 90%;
            width: 90%;
          }
          .cancel-button {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
