.form-row {
  @include container-column;

  margin-top: 26px;

  .form-row-inner {
    @include centered-container-row;
    width: 100%;
  }
  .title {
    flex: 1 100%;
    color: $colorLightPageDark;

    * {
      color: $colorLightPageDark;
    }
  }
  .elements {
    @include container-row;

    > * {
      &:not(:first-of-type) {
        margin-left: 6px;
      }
    }
  }
}
