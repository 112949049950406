$blockWidth: 234px;
.react-draggable {
  position: absolute;
  top: $topMenuHeight + $headerHeight;
  z-index: 3;
  right: 0;
}
.walkthrough {
  $bordeRadius: 6px;
  width: $blockWidth;
  border-radius: $bordeRadius;
  background: #444444;
  /*position: absolute;
  top: $topMenuHeight;*/
  overflow: hidden;

  &.collapsed {
    width: 60px;
    .dragger, .closer, .checkbox, .button-container {
      display: none;
    }
    .control {

      .toggler {
        width: 100%;
      }
    }

    .content {
      padding: 20px;

      .step {
        .text {
          opacity: 0;
        }

        &:last-of-type {
          .text {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .control {
    @include centered-container-row;

    padding: 6px 15px;
    background: #616161;
    border-radius: $bordeRadius;

    > div {
      cursor: pointer;
    }
    .toggler {
      @include button-center-bg('../../assets/images/icons/arrow-right-double.svg');
      width: 20px;
      height: 20px;
    }
    .flex {
      flex: 1;
    }
    .dragger {
      @include button-center-bg('../../assets/images/icons/drag.png');
      width: 20px;
      height: 20px;
      float: right;
      margin-right: 14px;
    }
    .closer {
      @include button-center-bg('../../assets/images/icons/closer-2.png');
      width: 20px;
      height: 20px;
    }
  }

  .content {
    @include container-column;

    padding: 20px 25px;

    .step {
      @include container-row;

      width: $blockWidth;

      .num-container {
        @include centered-container-column;
        padding-right: 10px;

        .num {
          @include middled-container;

          min-width: 22px;
          width: 22px;
          min-height: 22px;
          background: #8fc73e;
          font-size: $commontText;
          border-radius: 50%;
        }
        .line {
          flex: 1 100%;
          background: #8fc73e;
          width: 1px;
        }
      }

      .text {
        font-size: $commontText;
        padding-bottom: 20px;
        padding-top: 4px;
        width: 160px;
      }
    }

    .button-container {
      margin-top: 22px;

      .save-button {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
