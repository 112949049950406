.shadewave-confirm-dialog {
  width: 350px;
  min-height: 165px;

  .modal-header {
    padding: 10px 15px;
    .title {
      font-family: $fontFamilyRobotoMedium;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.04px;
      color: #FFF;
    }
  }

  .content {
    padding: 20px !important;
    .message-container {
      .message {
        font-family: $fontFamilyRobotoRegular;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.04px;
        color: #FFF;
      }
    }
    .footer {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;

      .confirm-button,
      .close-button {
        width: 45%;
        font-family: $fontFamilyRobotoRegular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #FFF;
      }
    }
  }

  &.light {
    background-color: #FFF !important;

    .modal-header {
      .title {
        color: #000;
      }
    }
    .content {
      .message-container {
        .message {
          color: #000;
        }
      }
      .footer {
        button {
          color: #fff;
          width: 95% !important;
          margin-left: 2.5%;

          &.close-button {
            background: #fff !important;
            color: #000;
            margin-left: 0;
            margin-right: 2.5%;
          }
        }
      }
    }
  }

  &.remove-connected-dentist-confirm-dialog,
  &.cancel-editing-confirm-dialog {
    width: 385px;
  }
}
