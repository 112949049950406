
.qc-main {
    @include container-column;
  
    overflow: hidden;
    padding: $containerPadding 19px;
    width: 100%;
    flex: 1 100%;
    height: $height;
    max-height: $height;
    transition: $transition;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;

    .qc-images-container {
        @include container-row;

        flex: 1 1 100%;
        align-self: stretch;

        .qc-image-container {
            flex: 1 50%;
            padding: 2px;
            min-width: 50px;
            min-height: 50px;
            position: relative;

            .qc-image {
                width: 100%;
                height: 100%;
                flex: 1 100%;
            }

            
        }

        .qc-crosshair-horz {
            position:absolute;
            width:100%;
            height:1px;
            background:transparent;
            top:50%;
            left:0px;
            border-bottom: dashed 2px;
        }

        .qc-crosshair-vert {
            position:absolute;
            height:100%;
            width:1px;
            background:transparent;
            left:50%;
            top:0px;
            border-left: dashed 2px;
        }

    }

    .qc-controls {
        @include container-row;
        @import "sub-menus/elements/row.scss";
        
        flex: 0 0 auto;
        align-self: stretch;
        //height: 150px;

        .qc-controls-left {
            @include container-column;
            flex: 0 0 auto;
            min-width: 150px;
            padding: 10px;
        }

        .qc-controls-right {
            @include container-column;
            flex: 0 0 auto;
            min-width: 150px;
            padding: 10px;
        }

        .qc-controls-left-middle {
            @include container-column;

            flex: 1 auto;
            align-self: center;
            min-width: 10px;
            padding: 10px;
        }

        .qc-controls-middle {
            @include container-column;
            flex: 1 100%;
            align-self: center;
            align-content: center;
            align-items: center;

            .qc-controls-checkboxes {
                @include container-row;
                //width: 200px;
                //max-width: 200px;
                flex: 1 100%;
                .column {
                    padding: 10px 15px;
                }
                .text {
                    font-size: 16px;
                }
            }
        }

        .qc-controls-right-middle {
            @include container-column;

            flex: 1 auto;
            align-self: center;
            min-width: 10px;
            padding: 10px;
        }

        div[disabled] {
            opacity: 0.4;
        }

        img[disabled] {
            opacity: 0.4;
        }

        .disabled {
            opacity: 0.4;
            
        }

        .shade-master-colors-area {
      
            margin: 0px 14px 0px 14px;
            padding: 0px 0px 0px 0px;
            background-color: #000000;
            .row-buttons {
                > input {
                    width: 54px;
                    height: 21px;
                    margin: 0 0 0px 0px;
                    border-radius: 2px;
                    background-color: #ffffff;
                    color: #000000;
                    padding-left: 4px;
                }
            }
          }

    }

    .qc-close-btn {
        position: absolute;
        top:0;
        left: 50%;
        margin-left: -36px;
    }

    .qc-print-btn {
        position: absolute;
        top: 48px;
        left: 50%;
        margin-left: -36px;
        width: 72px;
    }
}

.qc-print-area {
    -webkit-print-color-adjust: exact;
    padding: 20px;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    color: black;
    background-color: white;
    flex: 1 100%;
    flex-direction: column;
    display: flex;

    .qc-top-annotation {
        height: 120px;
        padding: 20px;
        flex: 0 auto;
        flex-direction: row;
        display: flex;
        background-color: aqua;
        align-self: stretch;
    }

    .qc-images {
        flex: 1 1 100%;
        flex-direction: row;
        //max-height: calc(100vh - 280px);
        align-self: stretch;
        flex-wrap: nowrap;

        .qc-image-left {
            flex: 1 1 50%;
            align-self: stretch;
            background-color: #535353;

            img {
                max-width:100%;
            }
        } 

        .qc-image-right {
            flex: 1 1 50%;
            align-self: stretch;
            background-color: yellow;

            img {
                max-width:100%;
            }
        } 

        
    }

    .qc-bottom-annotations {
        height: 120px;
        padding: 20px;
        flex: 0 auto;
        flex-direction: row;
        align-self: stretch;
        background-color: chocolate;
    }
}