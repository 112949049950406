$transition: .2s;

.process {
  font-size: $commontText;

  .action {
    margin-left: $padding;
  }
  .menu-title {

  }
  

    .shade-guides {
        
        padding: 7px 4px 7px 5px;
        background: #444444;
       


        .shade-guide {
            cursor: pointer;
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 14px 14px;
            &.active {
                background-image: url('../../../../../assets/images/icons/check-blue.png');

            }

            padding: calc($padding/2) 0;
        }
    }
  
}
