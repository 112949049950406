// imports from /app.scss because modal renders straight in the body
.shade-tabs-popup {
  max-width: unset !important;
  .content {
    @include container-row;
  }

  .block {
    @include container-column;

    &:first-of-type {
      margin-right: 1px;
    }
    .title {
      font-size: $mediumText;
    }

    .inner {
      min-width: 220px;
      width: 40vw;
      margin-top: 3px;
      background: #343434;
      max-height: 60vh;
      overflow-x: auto;
      flex: 1;
      position: relative;

      .shade-tab {
        padding: 15px 20px;
        cursor: pointer;

        &.selected {
          background: #38abe9;
        }
      }
    }
  }

  $buttonSize: 30px;

  .control {
    @include container-column;

    z-index: 2;
    position: absolute;
    top: 77px;
    left: calc(50% - #{$buttonSize} / 2);

    > div {
      cursor: pointer;
      width: $buttonSize;
      height: $buttonSize;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &:not(:last-of-type) {
        margin-bottom: 5px;
      }
      &.disabled {
        opacity: .7;
      }
    }

    .move-right {
      background-image: url('../../../../../../assets/images/icons/arrow-right-rounded.png');
    }
    .move-left {
      background-image: url('../../../../../../assets/images/icons/arrow-left-rounded.png');
    }
    .move-right-all {
      background-image: url('../../../../../../assets/images/icons/arrow-right-double-rounded.png');
    }
    .move-left-all {
      background-image: url('../../../../../../assets/images/icons/arrow-leftt-double-rounded.png');
    }
  }
}
