.create-case {
  .form-row {
    &.shade-guide, &.shade-tab {
      .title {
        width: 165px;
        flex: none;
      }
      .elements {
        flex: 1 100%;

        .select-box {
          flex: 1 50%;
        }
      }
    }
  }

  .shade-correct-checbox-row {
    margin-top: 50px !important;
    margin-bottom: 50px;

    .checkbox {
      .icon {
        width: 25px;
        height: 25px;
      }
      .text {
        margin-left: 20px;
      }
    }
    .with-required {
      font-size: 18px;
    }
  }
  .is-shade-correct {
    .required {
      margin-top: -5px;
    }
    .text {
      > span {
        color: #ff0000;
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
  .datepicker {
    .label {
      font-size: 12px;

      .required {
        display: none;
      }
    }
  }
  .inner {
    max-width: 1250px;
    padding:  0 43px;
    margin: 30px auto 0 auto;
  }

  form {
    textarea,
    input {
      cursor: default;
    }
  }
  .column-container {
    @include container-row;
  }
  .column {
    margin-top: 25px;

    &.first {
      margin-right: 50px;
      min-width: 445px;
    }
    &.last {
      flex: 1 100%;
    }
  }

  .form-row {
    .form-row-error {
      padding-left: 149px;
    }
  }

  .bottom {
    @include middled-container;

    flex-direction: column;
    margin-top: 32px;
    width: 100%;
  }
  .submit {
    width: 650px;
  }
  .input-group, .radio-group {
    &:not(:first-of-type) {
      margin-top: 26px;
    }

    textarea {
      height: 125px;
    }
    input[type="radio"] {
      &:focus ~ .checkmark {
        border-color: $colorLink;
      }
    }
  }
  .radio-group {
    label {
      margin-left: 20px;

      &:first-of-type {
        margin-left: 50px;
      }
      span {
        color: $lightPageFontColor;
        margin-left: 7px;
      }
    }
  }

  .shade-tab-select {
    .select-search__option {
      position: relative;
      width: 100%;
    }
    .shade-tab-color-value {
      position: absolute;
      height: 34px;
      width: 62px;
      right: 58px;
      top: 8px;
      border-radius: 5px;
    }
    .select-search__row {
      &:not(:first-of-type) {
        .shade-tab-color {
          top: 15px;
        }
      }
    }
    .result-row {
      @include container-row;

      padding-right: 0 !important;

      .name {
        flex: 1 100%;
      }
    }
    .shade-tab-color {
      width: 77px;
      height: 19px;
      border-radius: 5px;

      &.value {
        height: 36px;
        right: 8px;
        top: 8px;
      }
    }
    .select-search__value {
      background-position: calc(100% - 100px) center !important;
    }
  }
  .form-error {
    width: 650px;
    text-align: center;
  }

  @import "form-row";
  @import "file-upload";

  @include media('<=1100px') {
    .column {
      &.first {
        min-width: auto;
      }
    }
  }
  @include media('<=1000px') {
    .column {
      &.last {
        .shade-tab, .shade-guide {
          .form-row-inner {
            @include container-column;

            align-items: flex-start;

            .title {
              margin-bottom: 10px;
            }
            .elements {
              width: 100%;

              .select-box {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }

  @include media('<=880px') {
    .column {
      &.last {
        .shade-tab, .shade-guide {
            .elements {
              @include container-column;

              .select-box {
                width: 100% !important;
                margin-left: 0;
              }
            }
        }
      }
    }
  }

  @include media('<=750px') {
    .bottom {
      .button {
        width: 100%;
      }
    }
  }

  @include media('<=670px') {
    .column-container {
      @include container-column;

      .column {
        &.first {
          margin-right: 0;
        }
      }
    }
  }
}

.dialog-modal {
    * {
    color: #212529;
    }

    .products-box {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .products-formcontrol {
        margin: 8px;
        min-width: 120px 
    }

    .products-chips {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        & > * {
            margin: 4px;
        }
    }
}
