.shadewave-alert-dialog {
  width: 445px;
  min-height: 145px;
  height: auto;

  .content {
    padding: 25px 30px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    .message-container {
      .message {
        font-family: $fontFamilyRobotoRegular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #272727;
        width: 100%;
      }
    }
    .footer {
      button {
        width: 90px;
        height: 40px;
        border-radius: 5px;
        background-color: #8f49a4;
        font-family: $fontFamilyRobotoRegular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  &.light {
    background-color: #FFF !important;

    .content {
      .message-container {
        .message {
          color: #272727;
          white-space: pre-wrap;
        }
      }
    }
  }

  &.dark {
    .content {
        .message-container {
          .message {
            color: #ffffff;
            white-space: pre-wrap;
          }
        }
      }
  }
}

@import "secure-notes-alert-dialog";