$lineColor: #8fc73e;
$defaultSize: 128px;

@mixin size($width, $height) {
  height: $height;
  width: $width;
}
@mixin draw-progress($progress, $color) {
  .pie {
    .half-circle {
      border-color: $color;
    }

    .left-side {
      transform: rotate($progress * 3.6deg);
    }

    @if $progress <= 50 {
      .right-side {
        display: none;
      }
    } @else {
      clip: rect(auto, auto, auto, auto);

      .right-side {
        transform: rotate(180deg);
      }
    }
  }
}

.loader {
  @include middled-container;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $pageBG;
  z-index: 2;

  .inner {
    width: 70%;
    padding: 152px;
    border: 1px dashed #979797;
    border-radius: 8px;
  }

  .info {
    @include centered-container-column;
  }
  .text {
    @include light-bold;

    white-space: nowrap;
    margin-top: 27px;
    font-size: 21.6px;
    line-height: 1.29;
    text-align: center;
    color: #ffffff;
  }

  .dash {
    width: 20px;
    border-top: solid 1.1px #ffffff;
    margin-top: 10px;
  }

  .loader-circle {
    @include middled-container;

    width: 128px;
    height: 128px;
    position: relative;

    .pie {
      @include size(100%, 100%);
      clip: rect(0, $defaultSize, $defaultSize, calc($defaultSize / 2));
      left: 0;
      position: absolute;
      top: 0;
      opacity: 0.76;

      .half-circle {
        @include size(100%, 100%);

        transition: 1s;
        border: 25px solid #8fc73e;
        border-radius: 50%;
        clip: rect(0, calc($defaultSize / 2), $defaultSize, 0);
        left: 0;
        position: absolute;
        top: 0;
      }
    }

    .percentage {
      opacity: 0.63;
      font-size: 18px;
      color: #ffffff;
    }

    &.progress-25 {
      @include draw-progress(25, $lineColor);
    }
    &.progress-50 {
      @include draw-progress(50, $lineColor);
    }
    &.progress-75 {
      @include draw-progress(75, $lineColor);
    }
    &.progress-100 {
      @include draw-progress(100, $lineColor);
    }
  }
}
