.secure-notes-popup {
  width: 950px;
  min-height: 470px;
  max-height: 650px;
  height: auto;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: row !important;

  .secure-note {
    width: 100%;

    .modal-header {
      border-bottom: none !important;
      height: 50px;
      padding: 25px 15px 25px 15px;
    }

    .content {
      overflow-y: auto;
      max-height: 750px;
      padding: 10px 40px 25px 15px;

      .body {
        width: 100%;
        box-sizing: border-box;

        .label-container {
          display: flex;
          justify-content: space-between;

          .dicom-label,
          .stl-label {
            font-family: $fontFamilyMain;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;

            a {
              color: #38abe9;
              cursor: pointer;
              margin-left: 5px;
            }
          }
        }

        .textarea-container {
          margin-top: 20px;

          .input-group {

            textarea {
              width: 100%;
              height: 240px;
              resize: none;
              border-radius: 5px;
              color: #000;
              padding: 15px;
              border: none;

              &:focus + label,
              &:not(:placeholder-shown) + label {
                top: -15px;
              }
            }

            .label {
              font-family: $fontFamilyMain;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: left;
              color: #b2b2b2;
              z-index: 0;
            }
          }
        }

        .checkboxes-container {
          margin-top: 15px;
          display: flex;
          .checkbox-container {
            .checkbox {
              margin-left: 20px;
              .text {
                margin-left: 8px;
              }
              &.visible-by-dentist-checkbox {
                margin-left: 0;
              }

              &.disabled {
                .icon {
                  background-color: #c1c1c1;
                }
                .text {
                  color: #c1c1c1;
                }
              }
            }
          }
        }

        .footer {
          .buttons-container {
            position: relative;
            margin-top: 15px;
            display: flex;
            width: 100%;
            min-height: 35px;
            height: auto;

            .submit-button {
              position: absolute;
              margin-left: 15px;
              height: 37px;
              width: 95px;
              left: 250px;

              &:disabled {
                background-color: #c1c1c1;
                cursor: default;
              }
            }

            .dropzone-container {
              position: relative;

              .dropzone {
                .attach-button {
                  border-radius: 5px;
                  border: solid 1px #ffffff;
                  background-color: rgba(255, 255, 255, 0);
                  display: flex;
                  padding: 5px 16px;

                  span {
                    font-family: $fontFamilyMain;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ffffff;
                    margin-left: 10px;
                    height: 25px;
                    align-items: center;
                    display: flex;
                  }
                }
              }
              .uploaded-files-container {
                width: 100%;
                margin-top: 10px;
                position: relative;
                display: flex;
                height: min-content;
                flex-flow: wrap;

                .uploaded-files {
                  display: flex;
                  border-radius: 30px;
                  background-color: rgba(0, 0, 0, 0.3);
                  justify-content: space-between;
                  align-items: center;
                  padding: 5px 10px;
                  margin: 3px;

                  .uploaded-file {
                    font-family: $fontFamilyMain;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ffffff;
                  }

                  .remove-icon {
                    margin-left: 8px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
          .messages-container {
            margin-top: 20px;
            max-height: 390px;
            overflow-y: auto;

            .message-container {
              display: flex;
              flex-direction: column;
              background-color: #FFF;
              width: 100%;
              height: 120px;
              margin-top: 15px;
              border-radius: 5px;
              padding: 15px;

              .message-info,
              .message {
                label {
                  font-family: $fontFamilyMain;
                  font-size: 12px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  text-align: left;
                  color: #979797;
                }
              }

              .message {
                label {
                  margin-top: 10px;
                  color: #404040;
                }
              }
              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }

  .progress-bars-container {
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(100, 100, 100, 0.5);

    .saving-message,
    .loading-message,
    .loaded-photo-progress-bar {

      label {
        color: #000;
        font-size: 25px;
        font-family: $fontFamilyMain;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
      }
    }

    .saving-photos-message,
    .loaded-photo-progress-bar {
      .progress-bar-container {
        width: auto;
        height: 10px;
        border: 1px solid #FFF;
        box-sizing: content-box;

        .progress-bar {
          height: inherit;
          background: #c1c1c1;
        }
      }
    }
    .saving-message {
      .saving-photos-message {
        width: max-content;
        margin: 0 auto;
      }
    }
    &.has-messages {
      .secure-note {
        .content {
          padding: 0;

          .body {
            padding: 10px 40px 25px 15px;
            display: block;

            .textarea-container {
              .input-group {
                textarea {
                  height: 140px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.has-photos {
    max-height: 800px;
    min-width: 1050px;

    .secure-note {
      width: calc(100% - 150px);

      .content {
        padding: 0;

        .body {
          padding: 10px 40px 25px 15px;
          display: block;

          .textarea-container {
            .input-group {
              textarea {
                height: 140px;
              }
            }
          }
        }
      }
    }

    .photos-container {
      background-color: #3e3e3e;
      width: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-y: auto;
      padding: 20px 0;

      .photo-container {
        width: 85px;
        margin-top: 10px;

        .photo {
          width: 85px;
          height: 85px;

          img {
            border-radius: 5px;
            object-fit: cover;
            width: 85px;
            height: 85px;
          }
        }

        .photo-name {
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          label {
            font-family: $fontFamilyMain;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
          }
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}