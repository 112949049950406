.work-area {
  @include centered-container-row;

  @import "left-menu";
  @import "sub-menu";
  @import "photo-container";
  
  @import "qcview";
  @import "walkthrough";
  @import "cropper";

  background: #000;
  
  .fullscreen {
    width: 100%;
  }
}
