.light-page {
  .button {
    &.green {
      background-color: #6db413;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      letter-spacing: 1.25px;
      color: #ffffff;
      


      .button-loader {
        height: 11px;
      }
    }
  }
}
.button {
  @include light-bold;

  cursor: pointer;
  padding: 10px;
  border-radius: 5px;

  font-size: $commontText;
  border: none;

  &:active, &:focus {
    outline: 0;
    border: 0;
  }

  &.grey {
    background-color: #444444;
  }
  &.green {
    background-color: #8fc73e;
  }
  &.transparent {
    background: transparent;
    border: solid 1px #878686;
  }
  &.faded {
    opacity: .38;
  }
  &.square {
    width: 35px;
    height: 35px;
  }
  &:disabled {
    opacity: .38;
    cursor: default;
  }
  &.spacing {
    margin-right: 4px;
    margin-left: 4px;
  }

  &.progress {
    background-image: url('../../../../assets/images/loader-1.gif');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 22px;
    color: transparent;
  }
}
.upload-button {
  input[type="file"] {

  }
}
