.slider {
  align-items: center;
  flex: 1 100%;
  width: 100%;

  margin-top: 20px;

  .MuiSlider-root {
    width: calc(100% - 5px);
  }
  .reset {
    margin-top: 28px !important;
    font-size: 14px !important;
    text-align: center;
    text-transform: capitalize;
    /*position: absolute;
    right: 0;
    top: -15px;
    font-size: 11px;
    padding: 2px 8px;*/
  }
  .control {
    position: relative;
    z-index: 2;
    font-size: 9px;
    white-space: nowrap;
    padding: 4px 8px;
    border: 1px solid rgba(151, 151, 151, .5);
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    margin-top: -4px;

    &.decrement {
      float: left;
    }
    &.increment {
      float: right;
      margin-right: -2px;
    }
  }
  .MuiSlider-thumb {
    width: 23px;
    height: 23px;
    background-color: #ffffff;
    top: 9px;

    &::after {
      top: -15px;
      left: -15px;
      right: -15px;
      bottom: -15px;
      content: "";
      position: absolute;
      border-radius: 50%;
    }

    &.MuiSlider-active {
      box-shadow: 0 0 0 14px rgba(255, 255, 255, .1);
    }
  }

  .MuiSlider-track {
    background: transparent;
  }

  .MuiSlider-markLabel {
    left: 14px !important;
    position: absolute;
    color: #fff;
    top: -11px;
    font-size: 14px;
    width: 26px;

    &[data-index="1"] {
      left: unset !important;
      right: -18px;
    }
  }

  .Mui-disabled {
    opacity: 0.5;
  }

  $circleSize: 40px;
  .PrivateValueLabel-circle-4 {
    /*width: $circleSize;
    height: $circleSize;*/
  }
  .MuiSlider-valueLabel {
    top: 5px;
    left: calc(-50% - -7px);

    > span {
      color: transparent;
    }
    * {
      color: #000000;
      font-size: 12px;
    }
  }

  .MuiSlider-marked {
    margin-bottom: 0;
    /*margin-left: 5px;
    margin-right: 5px;*/
  }
}
