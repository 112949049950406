.ascend-selectcase-container {
	display: flex;
	flex: 1 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    
	.ascend-selectcase-form {
		display: flex;
		flex-direction: column;
		align-self: center;
		width: 450px;
		max-width: 450px;
		align-items: center;

		

        .ascend-row {
            display: flex;
            align-self: stretch; 
            padding: 6px;           
        }

        .ascend-error {
            border: 4px inset red;
            padding: 10px;
        }

        .ascend-success {
            border: 4px inset green;
            padding: 10px;
        }

			.input-group {
				margin-top: 20px;

				label {
					z-index: 0;
					padding: 0;
					font-size: 16px;
					top: 14px;
					color: #000;
				}

				&:first-of-type {
					margin-top: 0;
				}

				input {
					height: 50px;
					background: repeat-y;
					font-family: $fontFamilyRobotoRegular;

					&::placeholder {
						color: transparent;
					}

					@mixin focused-input {
						z-index: 1;
						font-size: 12px;
						color: $colorLink;
						top: -7px;
						padding: 0 10px;
					}

					&:not(:placeholder-shown) + label {
						@include focused-input;
					}
				}

				&.password {
					.right-icon {
						top: 15px;
					}
				}
			}

			.button {
				margin-top: 15px;
				width: 100%;
				border-radius: 4px;
				font-family: $fontFamilyRobotoMedium;
				box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12),
					0 1px 1px 0 rgba(0, 0, 0, 0.14);
				background-color: $colorLink;
				height: 35px;
				font-size: 14px;
				font-weight: 500;
				line-height: 1.14;
				letter-spacing: 1.25px;
				color: #ffffff;
				text-transform: uppercase;

				&.transparent {
					background-color: transparent;
					border: solid 1px $colorLink;
					color: $colorLink;
				}

				&.reset-password {
					background-color: #ffffff;
					color: #234361;
				}
			}
    }
}