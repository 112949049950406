@font-face {
  font-family: $fontFamilyMain;
  src: url(../../assets/fonts/AvenirNext/AvenirNextLTPro-Regular.otf);
}
@font-face {
  font-family: $fontFamilySecondary;
  src: url(../../assets/fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
  font-family: $fontFamilyIcon;
  src: url(../../assets/fonts/CustomIcons/CustomIcons.ttf);
}
@font-face {
  font-family: $fontFamilyRobotoMedium;
  src: url(../../assets/fonts/Roboto/Roboto-Medium.ttf);
}
@font-face {
  font-family: $fontFamilyRobotoRegular;
  src: url(../../assets/fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
  font-family: $fontFamilyAvenirNextDemiBold;
  src: url(../../assets/fonts/AvenirNext/AvenirNext-DemiBold.ttf);
}