$menuWidth: 219px;

$padding: 14px;
$padding-vertical: 8px;

.sub-menu {
  max-height: $workAreaHeight;
  min-height: $workAreaHeight;
  background: $colorDark;;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: -1;
  left: 0;
  position: relative;


  // resizeable
  > div {
    ///position: initial !important;

    min-width: $menuWidth;
    min-height: $workAreaHeight;
    height: $workAreaHeight;

    .resize-handler {
      > * {
        z-index: 1;
        right: -3px !important;
        width: 25px !important;
      }
    }
  }



  &.active {
    z-index: 3;
    left: 0;
    min-width: $menuWidth + $padding;
    padding-bottom: 20px;
  }

  * {
    font-size: $mediumText;
  }
  &::after {
    @include fake-bg;
    width: 22px;
    height: 23px;
    background-image: url('../../../../assets/images/icons/resize.svg');
  }

  .action {
    justify-content: flex-start !important;

    .radio-icon {
        height: 14px;
        width: 14px;
        min-width: 14px;
        margin-right: 4px;
        background-image: url('../../../../assets/images/icons/uncheck-blue.png');
        background-size: contain;
        background-repeat: no-repeat;

        &.active {
            background-image: url('../../../../assets/images/icons/check-blue.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .row-title {
      border-bottom: 1px dashed;
      float: left;
      flex: unset !important;
      cursor: pointer;

      &.disabled {
        border-bottom: 0px;
        opacity: 0.4;
        cursor: default;
      }


    }
  }
  .title {
    @include centered-container-row;

    text-transform: capitalize;
    height: 42px;
    cursor: pointer;
    padding: 13px $padding;

    font-size: $mediumText;
    img {
      width: 13px;
    }
    span {
      @include light-bold;

      margin-left: 6px;
      color: #ffffff;
    }
  }

  .buttons {
    @include justified-container-column;

    margin-top: 10px;

    .menu-button {
      @include centered-container-row;

      cursor: pointer;
      padding: 16px;
      position: relative;

      &.active, &:hover {
        background: #ddf0ff;

        .text {
          color: #000;
        }
      }
      .image-container {
        @include middled-container;

        width: 45px;
        height: 35px;
        margin-right: 12px;
      }
      img {
        max-width: 45px;
        max-height: 35px;
      }
      .text {
        @include light-bold;

        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      
      .closer {
        background-image: url('../../../../assets/images/icons/closer.svg');
        background-size: cover;
        background-repeat: no-repeat;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 8px;
        right: 5px;
        cursor: pointer;
      }
    }
  }

  @import "sub-menus";
  .padded {
    padding: 0 $padding;
    margin-top: 15px;
  }
  .switch-buttons-container {
    padding: 0 $padding;
    margin-bottom: $padding-vertical;

    .row-buttons {
      flex-direction: column;
      margin-top: $padding-vertical;

      > div {
        width: 100%;

        &:not(:first-of-type):not(.slider) {
          margin-top: $padding-vertical;
        }
      }

      .slider {
        margin-top: 16px;
      }
    }
  }


}
