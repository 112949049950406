html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}
body {
  background-color: #000;
  font-family: $fontFamilyMain;
  overflow: hidden;


}
* {
  box-sizing: border-box;
  color: #fff;
}

@media print {
    * {
        color: black;
    }
    body {
        -webkit-print-color-adjust: exact;
    }

    @page {
        size: landscape auto;
        //orientation: landscape;
        margin: 1cm;
    }

    .qc-print-area {
        -webkit-print-color-adjust: exact;
        //padding: 20px;
        width: 100%;
        //max-width: 100vw;
        height: 100%;
        //max-height: 100vh;
        color: black;
        background-color: white;
        flex: 1 100%;
        flex-direction: column;
        align-items: stretch;
        align-self: stretch;
        align-content: flex-start;
        justify-content: flex-start;
        display: flex;

        .qc-top-annotation {
            height: 120px;

            flex: 0 auto;
            flex-direction: row;
            display: flex;
            align-self: stretch;
        }

        .qc-images {
            flex: 0 0 content;
            flex-direction: row;
            //max-height: calc(100vh - 280px);
            align-self: stretch;
            flex-wrap: nowrap;
            display: flex;
            width: 100%;

            .qc-image-left {
                flex: 1 1 50%;
                align-self: stretch;
                display: flex;
                padding: 20px;

                img {
                    align-self: flex-start;
                    max-width: 100%;
                }
            }

            .qc-image-right {
                flex: 1 1 50%;
                align-self: stretch;
                display: flex;
                padding: 20px;

                img {
                    align-self: flex-start;
                    max-width: 100%;
                }
            }
        }

        .qc-bottom-annotations {
            height: 120px;

            flex: 0 0 auto;
            flex-direction: row;
            align-self: stretch;
            display: flex;
            //justify-self: flex-end;
        }

        .qc-bottom-space {
            flex: 1 100%;
            align-self: stretch;
            //justify-content: flex-end;
        }

        .block-text-annotation {
            padding: 20px;
            flex: 1 50%;
            flex-direction: column;
            display: flex;
        }

        .text-label {
            white-space: pre;
            color: black !important;
            background: white !important;
            -webkit-print-color-adjust: exact;
        }
    }

    .print-area {
        -webkit-print-color-adjust: exact;
        //padding: 20px;
        width: 100%;
        height: 100%;
        color: black;
        flex: 1 1 100%;
        flex-direction: column;
        display: flex;
        align-content: stretch;
        align-items: stretch;
        //background-color: blue;

        .container-text-annotation {
            height: 120px;
            padding: 20px;
            flex: 0 0%;
            flex-direction: row;
            display: flex;

            .block-text-annotation {
                flex: 1 50%;
                flex-direction: column;
                display: flex;

                .text-label {
                    white-space: pre;
                    color: black !important;
                    background: white !important;
                    -webkit-print-color-adjust: exact;
                }
            }
        }

        .img-print-content {
            display: flex;
            flex: 1 1 100%;
            flex-direction: row;
            align-self: center;
            align-content: center;
            align-items: center;
            padding: 10px;
            //background-color: yellow;
            width: auto;
            max-width: 100%;
            
        }

        @media (orientation: landscape) {
            .img-print {
                display: flex;
                flex: 1 1 auto;
                align-self: center;
                max-height: calc(100vh - 160px);
                width: auto;
                max-width: 100%;
                //background-color: red;
            }
        }

        @media (orientation: portrait) {
            .img-print {
                display: flex;
                flex: 1 1 auto;
                align-self: start;
                height: auto;
                max-width: calc(100vw - 60px);
                //background-color: red;
            }
        }
    }
}


a {
  text-decoration: none;
}

.mobile-element {
  display: none;
}
*:not(.default-scroll) {
$scrollBgColor: #424242;
$scrollSideBorder: 3px solid $scrollBgColor;
$scrollTopBorder: 18px solid $scrollBgColor;

.dark-page {
    ::-webkit-scrollbar {
        width: 15px;
        background: $scrollBgColor;
    }
    ::-webkit-scrollbar-track {
        background: #535353;
        /*border-left: $scrollSideBorder;
        border-right: $scrollSideBorder;
        border-bottom: $scrollTopBorder;
        border-top: $scrollTopBorder;*/
    }
    ::-webkit-scrollbar-thumb {
        background-color: #7c7c7c;
        border-left: $scrollSideBorder;
        border-right: $scrollSideBorder;
    }
}

}
