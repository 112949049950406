@mixin field-text {
  font-size: 12px;
  line-height: 1.5;
  color: #d8d8d8;
}
.details {
  padding: $padding;

  .date-info {
    @include centered-container-row;

    .date {
      font-size: 36px;
      color: #ffffff;
    }

    .info {
      margin-left: 4px;
      font-size: 14px;
    }
  }

  .fields {
    margin-top: 14px;

    .field {
      @include container-row;

      margin-top: 3px;
      margin-bottom: 3px;
      align-items:center;

      .field-title {
        @include container-row;
        @include field-text;

        white-space: nowrap;
        min-width: 60px;
        flex: 0 0 auto;

        &.category {
          margin-top: 10px;
          opacity: .4;
        }
        .text {
          flex: 1;
        }

        .colon {

        }
      }
      .field-value {
        @include field-text;

        display: inline-block;
        align-items: center;
        margin-left: 5px;

        /*word-break: break-all;*/
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }
  }
}
