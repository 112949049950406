.edit-dentist-profile-container {
  @mixin form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .passwords-group {
      margin-top: 10px;

      .password {
        .right-icon {
          top: 15px;
        }
      }
    }

    .input-group {
      margin-top: 10px;

      label {
        z-index: 0;
        padding: 0;
        font-size: 16px;
        top: 14px;
        left: 16px;
        font-family: $fontFamilyRobotoRegular;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: $lightPageFontColorLighter;
      }
      &:first-of-type {
        margin-top: 0;
      }

      input {
        height: 50px;
        font-family: $fontFamilyRobotoRegular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.15px;
        color: $lightPageFontColorLighter;
        cursor: default;

        &::placeholder {
          color: transparent;
        }

        &:focus + label,
        &:not(:placeholder-shown) + label {
          z-index: 1;
          font-size: 12px;
          color: $colorLink;
          top: -8px;
          padding: 0 5px;
          line-height: 1.33;
          letter-spacing: 0.4px;
        }
      }
    }
    .select-box {
      margin-top: 10px;
      height: 50px;
      position: relative;

      .placeholder {
        top: 14px;

        &.has-value {
          top: -8px;
        }
      }
      .select-search {
        height: 50px;

        .input-group {
          input::placeholder {
            color: initial;
          }
        }
      }
    }
  }
  background-color: #FFFFFF;
  height: 100vh;
  width: 100%;

  .edit-dentist-profile {
    display: flex;
    flex-direction: row;
    margin-top: 3%;

    .profile-info  {
      width: 47.5%;
      padding: 0 2.5% 0 9.5%;

      .edit-dentist-title {
        display: flex;
        flex-direction: column;

        label {
          font-family: $fontFamilyRobotoMedium;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: -0.06px;
          color: #212529;
        }

        .account-details-title {
          font-family: $fontFamilyRobotoRegular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: -0.05px;
          color: $lightPageFontColor;
          margin-top: 5px;
        }
      }

      .additional-email-case-assign {
        font-family: $fontFamilyRobotoRegular;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.15px;
        color: $lightPageFontColorLighter;
        margin-top: 10px;
        display: block;
      }
      .checkbox {
        margin-top: 15px;

        .text {
          font-family: $fontFamilyRobotoRegular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: -0.05px;
          color: $lightPageFontColor;
          height: auto;
          display: flex;

          .form-required {
            line-height: 1;
          }
        }

        .icon {
          border-radius: 0;
          border: 2px solid #425a70;
        }
      }
      .form {
        @include form;
        margin-top: 30px;
        .hidden {
          display: none;
        }
      }

      .input-placeholder {

        &.disabled {
            background-color: rgb(242, 242, 242);
        }
    }
    }

    .more-doctors-form {
        @include form;
        .hidden {
            display: none;
        }
    }

    .lab-specialist-container {
      width: 47.5%;
      padding-right: 4%;
      height: auto;

      .info-container {
        display: flex;
        justify-content: space-between;
        margin-top: 17px;
        min-height: 35px;

        .customer-number-container {
          width: 55%;
        }
        .renewal-date-container {
          width: 40%;
        }

        .renewal-date-container,
        .customer-number-container {
          display: flex;
          justify-content: space-between;
          border-bottom: solid 1px #979797;
          padding-bottom: 5px;

          .name {
            font-family: $fontFamilyRobotoRegular;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.17px;
            color: $lightPageFontColorLighter;
          }

          .value {
            font-family: $fontFamilyRobotoMedium;
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.17px;
            color: rgba(0, 0, 0, 0.8);
          }
        }
      }

      .lab-specialist {
        border-radius: 5px;
        border: dashed 1px #979797;
        background-color: #f2f2f2;
        height: calc(100% - 85px);
        padding: 7%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;

        .lab-form-container,
        .specialist-form-container {
          height: calc(50% + 40px);
          .lab-container,
          .specialist-container {
            height: 100%;

            .add-title,
            .lab-title,
            .specialist-title {
              font-family: $fontFamilyRobotoMedium;
              font-size: 18px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: 0.17px;
              color: rgba(0, 0, 0, 0.9);
            }

            .add-title {
              margin: 10px 0;
            }

            .horizontal-line {
              background-color: #979797;
              margin-top: 10px;

              &.top {
                margin: 10px 0;
              }
            }

            .labs,
            .specialists {
              max-height: 85px;
              overflow-y: auto;
              .lab,
              .specialist {
                display: flex;
                justify-content: space-between;
                margin: 5px 0;
                .lab-name,
                .specialist-name {
                  font-family: $fontFamilyRobotoRegular;
                  font-size: 16px;
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: 0.15px;
                  color: $lightPageFontColorLighter;
                }

                .actions {
                  .action {
                    font-family: $fontFamilyRobotoRegular;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: 0.15px;
                    color: $lightPageFontColorLighter;
                    cursor: pointer;
                    background-color: transparent;
                    border: none;
                  }
                  span {
                    margin: 0 10px;
                  }
                }
              }
            }

            .lab-form,
            .specialist-form {
              width: 100%;
              @include form;
              .input-group {
                margin-top: 7px;

                label {
                  top: 5px;
                  font-size: 14px;
                  line-height: 1.71;
                  letter-spacing: 0.13px;
                }
                input {
                  height: 32px;
                  padding: 14px;
                  &:focus + label,
                  &:not(:placeholder-shown) + label {
                    z-index: 1;
                    top: 0 !important;
                    line-height: 0.2;
                  }
                  &:focus {
                    padding: 0 13px;
                  }
                }
              }

              .search-button {
                width: 100%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: $fontFamilyRobotoRegular;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: 0.13px;
                text-align: center;
                color: #ffffff;
                border-radius: 4px;
                background-color: #90c740;
                margin-top: 5px;
              }
            }
            .labs-collapse,
            .specialists-collapse {
              margin: 10px 0;

              .table-container {
                .scroll-container {
                  width: 100% !important;
                }
                .header,
                .body {
                  .row {
                    .cell {
                      flex: unset;
                      display: inline-block;
                      padding: 5px 0 5px 15px;
                      width: 32.5%;
                    }
                    .first-cell {
                      width: 22.5%;
                    }
                    .last-cell {
                      width: 45%;
                    }
                  }
                }
                .body {
                  .row {
                    height: auto;
                    .last-cell {
                      overflow-wrap: break-word;
                      word-wrap: break-word;
                      white-space: unset;
                      hyphens: auto;
                    }
                    .no-result {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    .buttons-container {
      width: 100%;
      display: flex;
      justify-content: space-around;

      .save-button,
      .cancel-button {
        width: 45%;
        height: 40px;
        max-width: 500px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
        font-family: $fontFamilyRobotoMedium;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 1.25px;
        text-align: center;
        color: #ffffff;
        border: none;
      }

      .save-button {
        background-color: #6db413;
      }
    }

    .support-info {
      margin-top: 20px;
      font-family: $fontFamilyRobotoRegular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;

      a {
        color: $colorLink;
      }
    }
  }
}

@include media('<=900px') {
  .edit-dentist-profile-container {
    .edit-dentist-profile {
      .lab-specialist-container {
        .info-container {
          flex-direction: column;
          .customer-number-container,
          .renewal-date-container {
            width: 100%;
          }
          .renewal-date-container {
            margin-top: 15px;
          }
        }
        .lab-specialist {
          padding: 0 6%;
          height: calc(100% - 120px);
          .lab-container {
            margin: 6% 0 3% 0;
          }
          .specialist-container {
            margin: 3% 0 6% 0;
          }
        }
      }
    }
  }
}
@include media('<=700px') {
  .edit-dentist-profile-container {
    .edit-dentist-profile {
      flex-direction: column;

      .profile-info,
      .lab-specialist-container,
      .additional-info {
        width: 100%;
        padding: 0 5%;
      }
      .lab-specialist-container {
        .lab-specialist {
          padding: 0 6%;
          .lab-container {
            margin: 6% 0 3% 0;
          }
          .specialist-container {
            margin: 3% 0 6% 0;
          }
        }
      }
    }
    .footer {
      .buttons-container {
        flex-direction: column;
        align-items: center;

        .save-button,
        .cancel-button {
          max-width: 90%;
          width: 90%;
        }
        .cancel-button {
          margin-top: 15px;
        }
      }
    }
  }
}
