.file-upload {
  @include middled-container;
  @include container-column;

  margin-top: 32px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.32);
  min-height: 220px;
  cursor: pointer;

  .file-upload-inner {
    @include centered-container-column;
  }

  .placeholder {
    width: 65px;
  }
  .title {
    margin-top: 20px;
    font-size: 14px;
    letter-spacing: -0.05px;
    color: $lightPageFontColor;

    &.files-uploaded {
      padding: 10px 0;
      margin-top: 0;
    }
  }

  .files {
    padding: 10px;
    flex: 1;
    width: 100%;
    align-items: flex-start;
    position: relative;

    .file {
      @include middled-container;

      height: 70px;
      width: 25%;
      position: relative;
      bordeR: 1px dotted;
      float: left;
      padding: 10px;
      overflow: hidden;

      img {
        margin-right: 10px;
        max-width: 50px;
        max-height: 50px;
      }
      .name {
        font-size: 12px;
        flex: 1 1 100%;
        text-overflow: ellipsis;
        max-width: 78px;
        overflow: hidden;
      }
      .closer {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-image: url('../../../assets/images/closer-black.svg');
        background-position: center;
        background-size: cover;
        cursor: pointer;
      }
    }
    .title-bottom {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
}
