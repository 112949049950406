// imports from /app.scss because modal renders straight in the body
.cases-modal {
  min-width: 800px;

  .loader {
    position: relative;
    background: transparent;
    width: 100%;
    height: auto;

    .inner {
      padding: 40px 152px;
      width: 100%;
      background: #343434;
    }
  }
  .content {


  }
  .cases {
    position: relative;
    max-height: 80vh;

    .body {
      overflow-y: scroll;

      .row {
        cursor: pointer;
      }
    }

    .cell {
      text-align: center;
    }
    // date
    .cell:nth-child(1) {
      flex: 1 15%;
    }
    // doctor first
    .cell:nth-child(2) {
      flex: 1 25%;
    }
    // doctor last
    .cell:nth-child(3) {
      flex: 1 25%;
    }
    // patient
    .cell:nth-child(4) {
      flex: 1 20%;
    }
    // status
    .cell:nth-child(5) {
      flex: 1 15%;
    }

    .status {
      padding: 5px 10px;
      text-transform: capitalize;
      border-radius: 5px;
      display: inline;

      &.new {
        background: #2c77d7;
      }
      &.complete {
        background: #1a9727;
      }
    }
  }
}
