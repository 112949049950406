.switch-buttons {
  @include container-row;

  margin-top: 12px;
  padding: 2px;
  border-radius: 3px;
  border: solid 0.5px #ffffff;

  &.column {
      flex-direction: column;
  }

  > .switch-button {
    cursor: pointer;
    flex: 1 50%;
    text-align: center;
    padding: 3px 2px;
    border-radius: 3px;
    align-self: center;

    &.active {
      background: #ffffff;
      color: #3278be;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
}
