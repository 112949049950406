.light-page {
  .input-group {
    textarea {
      border: solid 1px rgba(0, 0, 0, 0.32);
      font-family: $fontFamilySecondary;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.15px;
      padding: 16px;
    }
  }
}
.input-group {
  textarea {
    border-radius: 4px;
    outline: none;
    width: 100%;

    &::placeholder {
      color: transparent;
    }

    @mixin focused-input {
      top: -10px;
      z-index: 1;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      @include focused-input;
    }
  }

}
