$arrowWidth: 8px;
$boxShadow: 0 5px 10px 0 rgba(0, 0, 0, 0.04);

.expanded-user-menu {
  @include container-column;

  position: absolute;
  right: 0;
  background: #fff;
  border-radius: 5px;
  z-index: 1;
  top: calc(#{$headerHeight} + #{$arrowWidth});
  box-shadow: $boxShadow;

  .user {
    cursor: pointer;
    padding: 15px 15px 7px 15px;
    border-bottom: 1px solid #e8e8e8;

    .name {
      @include light-bold;

      font-size: 14px;
      color: #000000;
    }
    .id {
      margin-top: 2px;
      font-size: 10px;
      color: #c3c3c3;
    }
  }
  .control {
    @include container-column;

    padding: 15px 15px;

    .link {
      @include centered-container-row;

      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
      > span {
        flex: 1;
        display: flex;
      }
      .text {
        flex: 1 100%;
        white-space: nowrap;
      }
      .icon-container {
        margin-right: 7px;

        img {
          width: 15px;
        }
      }
      span {
        @include light-bold;

        font-size: 14px;
        color: #000000;
      }
    }
  }
  .arrow-up {
    width: 0;
    height: 0;
    border-left: $arrowWidth solid transparent;
    border-right: $arrowWidth solid transparent;
    border-bottom: $arrowWidth solid #fff;
    position: absolute;
    right: 20px;
    top: -$arrowWidth;
    box-shadow: $boxShadow;
  }
}
