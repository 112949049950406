@mixin container-column {
  display: flex;
  flex-direction: column;
}
@mixin container-row {
  display: flex;
  flex-direction: row;
}

@mixin centered-container-column {
  @include container-column;

  align-items: center;
}
@mixin justified-container-column {
  @include container-column;

  justify-items: center;
  justify-content: center;
}

@mixin centered-container-row {
  @include container-row;

  align-items: center;
}
@mixin justified-container-row {
  @include container-row;

  justify-items: center;
  justify-content: center;
}

@mixin justify-content-right {
  display: flex;
  justify-content: flex-end;
}

@mixin middled-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin light-bold {
  font-weight: 500;
}
@mixin fake-bg {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  background-position: center;
  background-repeat: no-repeat;
}
@mixin button-center-bg($url) {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url($url);
}
