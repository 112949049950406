.secure-note-alert-dialog {
  height: auto;
  background-color: #545353 !important;

  label {
    font-family: $fontFamilyMain;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    a {
      color: #38abe9;
    }
  }
}