.create-lab-profile-container {

  display: flex;
  flex-flow: column;
  background-color: #FFFFFF;
  height: 100vh;
  width: 100%;

  .stepper-container {
    width: 200px;
    margin: 0 auto;
  }
  .create-lab-title {
    padding-top: 3.5%;

    label {
      padding-left: 9.5%;
      font-family: $fontFamilyRobotoMedium;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: -0.06px;
      color: #212529;
    }
  }

  .create-lab-profile {
    display: flex;
    flex-direction: column;

    .fields {
      display: flex;
      flex-direction: row;

      .passwords-group {
        margin-top: 10px;

        .password {
          .right-icon {
            top: 15px;
          }
        }
      }

      .input-group {
        margin-top: 10px;

        label {
          z-index: 0;
          padding: 0;
          font-size: 16px;
          top: 14px;
          font-family: $fontFamilyRobotoRegular;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.15px;
          color: $lightPageFontColorLighter;
        }
        &:first-of-type {
          margin-top: 0;
        }

        input {
          height: 50px;
          font-family: $fontFamilyRobotoRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: 0.15px;
          color: $lightPageFontColorLighter;
          &::placeholder {
            color: transparent;
          }
          @mixin focused-input {
            z-index: 1;
            font-size: 12px;
            color: $colorLink;
            top: -8px;
            padding: 0 5px;
            line-height: 1.33;
            letter-spacing: 0.4px;
          }
          &:not(:placeholder-shown) + label {
            @include focused-input;
          }
        }
        &.website {
          margin-top: 50px;
        }
      }
      .select-box {
        margin-top: 10px;
        height: 50px;
        position: relative;

        .placeholder {
          top: 14px;

          &.has-value {
            top: -8px;
          }
        }
        .select-search {
          height: 50px;

          .input-group {
            input::placeholder {
              color: initial;
            }
          }

          &.has-focus + .placeholder {
            top: -8px;
          }
        }
      }
      .profile-info {
        width: 47.5%;
        padding: 0 2.5% 0 9.5%;

        .create-lab-profile-select {
          width: 100%;

          svg {
            fill: #000;
          }
        }
      }

      .profile-info,
      .additional-info {
        margin-top: 2%;

        .additional-email-case-assign {
          font-family: $fontFamilyRobotoRegular;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.15px;
          color: $lightPageFontColorLighter;
          margin: 15px 0;
          display: block;

          &.update {
            margin-top: 0;
          }
        }
        .checkbox {
          margin-top: 15px;

          .text {
            font-family: $fontFamilyRobotoRegular;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: -0.05px;
            color: $lightPageFontColor;
            height: auto;
          }

          .icon {
            border-radius: 0;
            border: 2px solid #425a70;
          }
        }
      }

      .additional-info {
        width: 52.5%;
        padding: 0 17% 0 0;
      }
    }
    .footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 40px 0 20px 0;

      .save-button {
        width: 35%;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
        background-color: #6db413;
        font-family: $fontFamilyRobotoMedium;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: 1.25px;
        text-align: center;
        color: #ffffff;
      }

      .footer-text {
        margin-top: 18px;
        font-family: $fontFamilyRobotoRegular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #000000;

        a {
          color: $colorLink;
        }
      }
    }
  }
}
@include media('<=700px') {
  .create-lab-profile-container {
    .create-lab-profile {
      flex-direction: column;

      .fields {
        display: flex;
        flex-direction: column;

        .input-group {
          &.website {
            margin-top: 10px;
          }
        }
        .profile-info,
        .additional-info {
          width: 100%;
          padding: 0 5%;

          .additional-email-case-assign {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
