.row {
  @include centered-container-row;
  @include middled-container;

  &.column {
    margin-top: 13px;
    flex-direction: column;
    align-items: flex-start;

    .row-buttons {
      flex: 1 100%;
      width: 100%;
    }

    
  }

  &.incremental-rotate {
    margin-top: -10px;
  }

  &.big {
    margin-top: 15px !important;

    .row-buttons {
      margin-top: 10px;
      margin-bottom: 10px;

      > div {
        width: 100%;
      }
    }
  }

  min-height: 40px;
  padding: 0;

  &.left {
    justify-content: left;
  }

  .row-title {
    flex: 1 100%;
    padding-right: 10px;

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
  .row-buttons {
    @include centered-container-row;

    position: relative;

    img {
      cursor: pointer;

      &:first-of-type {
        margin-right: 4px;
      }
    }

    
  }

  &.shade-limits-row {
    margin-top: 4px;
  }

  .degrees-container {
      position: relative;

      .degrees-icon-up {
        position: absolute;
        right: 28px;
        top: 0px;
        width: 28px;
        border-radius: 4px;
        height: 28px;
        border: solid 0.5px #979797;
        background-color: #272727;
        background-size: contain;
        background-image: url('../../../../../../assets/images/icons/plus.png');
        
      }
      .degrees-icon {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 28px;
        border-radius: 3px;
        height: 28px;
        border: solid 0.5px #979797;
        background-color: #272727;
        background-size: contain;
        background-image: url('../../../../../../assets/images/icons/minus.png');
        
      }
      input.degrees {
        width: 100%;
        height: 28px;
        border-radius: 3px;
        border: solid 0.5px #979797;
        background: transparent;
        font-size: 14px;
        font-weight: 500;
        padding: 2px 4px;
    
        ~div[disabled] {
            opacity: 0.4;
        }
      }
      input.degrees:disabled {
          opacity: 0.4;
      }
  }

  .degrees-slider-2 {
    margin-left: 0px;
    margin-right: 10px;
  }

  .degrees-slider {
    margin-left: 0px;
    margin-right: 10px;

    .MuiSlider-thumb {
      width: 27px;
      height: 23px;
      background-color: #ffffff;
      top: 7px;
  
      &::after {
        top: -15px;
        left: -15px;
        right: -15px;
        bottom: -15px;
        content: "";
        position: absolute;
        border-radius: 50%;
      }
  
      &.MuiSlider-active {
        box-shadow: 0 0 0 14px rgba(255, 255, 255, .1);
      }

      &.Mui-disabled {
        background-color:#979797 ;
      }
      
    }
  
    .MuiSlider-track {
      background: transparent;
    }
  
    .MuiSlider-markLabel {
      left: 14px !important;
      position: absolute;
      color: #fff;
      top: -11px;
      font-size: 14px;
      width: 30px;
  
      &[data-index="1"] {
        left: unset !important;
        right: -18px;
      }

      
    }

    .MuiSlider-valueLabel {
      top: 6px;
      left: calc(-50% - -10px);
  
      > span {
        color: transparent;
      }
      * {
        color: #000000;
        font-size: 12px;
      }
    }
  
    .MuiSlider-marked {
      margin-bottom: 0;
      /*margin-left: 5px;
      margin-right: 5px;*/
    }
  }

  .save-degrees-btn {
    height: 28px;
    padding: 5px 10px;
    margin: 0px 2px;
    margin-left: 10px;
  }


  

  .switch-buttons {
    margin-top: 0;
  }

  @import "slider";
}
