.maps {
  padding: 0 $padding;

  .switch-buttons-container {
    padding-left: 0;
    padding-right: 0;
  }

  .action {
    //&.active {
    //  background-image: url('../../../../../assets/images/icons/check-blue.png');
    //  background-repeat: no-repeat;
    //  background-position: right center;
    //  background-size: 14px 14px;
    //}
    &.disabled {
      opacity: .7;
    }
  }

  

    .select-items {
      padding: 7px 4px 7px 5px;
      background: #444444;
      //margin-left: $padding;


      .select-item {
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: right center;
        background-size: 14px 14px;
        &.active {
          background-image: url('../../../../../assets/images/icons/arrow-down.svg');

        }

          padding: calc($padding/2) 0;
      }
    }
  
}
