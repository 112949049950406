.light-page {
  .input-group {
    > * {
      width: 100%;
    }
    label {
      @include container-row;

      font-family: $fontFamilySecondary;
      letter-spacing: 0.4px;
      //color: #883b9f;
      background: #fff;
      padding: 0 5px;
      width: auto;
      z-index: 0;
      font-size: 16px;
      top: 14px;
      line-height: 1.5;
      color: $lightPageFontColorLighter;
    }
    * {
      color: $colorLightPageDark;
    }
    input {
      width: 100%;
      height: 50px;
      border-radius: 4px;
      border: solid 1px rgba(0, 0, 0, 0.32);
      font-family: $fontFamilySecondary;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.15px;
      padding: 16px;

      &:disabled, &:read-only {
        background: #f2f2f2;
      }
      &:focus {
        padding: 0 15px;
        border: solid 2px $colorLink;
      }
    }
  }
}
.input-group {
  position: relative;

  @mixin focused-input {
    top: -8px !important;
    padding: 0 10px !important;
    z-index: 1;
  }

  &.datepicker {
    label {
      @include focused-input;
    }
  }
  label {
    position: absolute;
    z-index: -1;
    transition: $transition;
    left: 12px;
    padding: 0 6px;
    top: 10px;
  }

  input  {
    outline: none;

    &::placeholder {
      color: transparent;
    }

    &:focus + label,
    &:not(:placeholder-shown) + label {
      @include focused-input;

      font-size: 12px !important;

      .required {
        display: none;
      }
    }
  }

  .required {
    color: rgba(255, 0, 0, 1);
    margin-right: 4px;
    margin-top: -5px;
    font-size: 23px;
  }

  &.password {
    .right-icon {
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 10px;
      top: 9px;
      z-index: 2;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &.show {
      .right-icon {
        filter: contrast(0);
      }
    }
  }

  &.has-error {
    input {
      box-shadow: rgb(255, 0, 0) 0 0 1.5px 1px;

      &:not(:placeholder-shown) + label {
        line-height: -2px;
        top: -2px;

        .required {
          display: none;
        }
      }
    }
    
  }

  .error {
    font-family: $fontFamilyRobotoRegular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.13px;
    color: rgb(255, 0, 0);
  }

}
.autocomplete-input-container {
  position: relative;

  .results {
    position: absolute;
    width: 100%;
    border: solid 1px rgba(0, 0, 0, 0.32);
    z-index: 2;
    background: #fff;
    max-height: 200px;
    overflow: auto;

    .result-row {
      padding: 10px 15px;

      &.active, &:hover {
        background: #bbfff6;
      }
    }
  }
}
